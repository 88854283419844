import axios from "axios";
const base = "/api";
const configJsonHeaders = {
  headers: {
    Accept: "application/json",
  },
};
const apiService = {
  createRepresentante(data) {
    return axios.post(`${base}/Representantes/New`, data, configJsonHeaders);
  },
  disableRepresentante(guid){
    return axios.get(`${base}/Representantes/Disable/${guid}`, configJsonHeaders);
  },
  contact(data) {
    return axios.post(`${base}/public/Contact`, data, configJsonHeaders);
  },
  getMeeting(guid) {
    return axios.get(`${base}/public/GetMeeting/${guid}`, configJsonHeaders);
  },
  getMeetingDate(guid) {
    return axios.get(`${base}/public/GetMeetingDate/${guid}`, configJsonHeaders);
  },  
  isPropietarioOrRepresentante(data) {
    return axios.post(`${base}/JuntaVirtual/IsPropietarioOrRepresentante`, data, configJsonHeaders);
  },
  login(data) {
    return axios.post(`${base}/JuntaVirtual/Login`, data, configJsonHeaders);
  },
  isLogin(convocatoriaId, code) {
    return axios.get(`${base}/JuntaVirtual/IsLogin?convocatoriaId=`+convocatoriaId + "&code="+code, configJsonHeaders);
  },
  logOut(data) {
    return axios.post(`${base}/JuntaVirtual/Logout/`, data, configJsonHeaders);
  },
  registerInConvocatoria(data) {
     return axios.post(`${base}/JuntaVirtual/RegisterInConvocatoria`, data, configJsonHeaders);
  },
  isPropietarioRepresentado(data) {
    return axios.post(`${base}/JuntaVirtual/IsPropietarioRepresentado`, data, configJsonHeaders);
  },
   getCodesUserLogin(data) {
    return axios.post(`${base}/JuntaVirtual/GetCodesUserLogin`, data, configJsonHeaders);
  },
  updateVotacion(data){
    return axios.put(`${base}/JuntaVirtual/UpdateVotacion`, data, configJsonHeaders); 
  },
  getEnums() {
    return axios.get(`${base}/Enum/GetAll`, configJsonHeaders);
  },
  getPuntosConvocatoria(guid) {
    return axios.get(`${base}/PuntosConvocatorias/GetAllByConvocatoriaWithPreguntas/${guid}`, configJsonHeaders);
  },
  getPuntosConvocatoriaWithVotaciones(guid, code) {
    return axios.get(`${base}/PuntosConvocatorias/GetAllByConvocatoriaWithPreguntasAndVotaciones/${guid}/${code}`, configJsonHeaders);
  },
  isVotacionAbierta(guid){
    return axios.get(`${base}/PuntosConvocatorias/IsVotacionAbierta/${guid}`, configJsonHeaders);   
  },
  getMeetingLogin(guid) {
    return axios.get(`${base}/Convocatorias/Get/${guid}`, configJsonHeaders);
  },
  isVotacionAbiertaOfPregunta(guid){
    return axios.get(`${base}/Preguntas/IsVotacionAbierta/${guid}`, configJsonHeaders);   
  },
};

export default apiService;
