<template>  
 <section v-if="convocatoria != null" class="section section-md sectionWithOutMargin">
    <div class="container">
      <div class="row">
        <div class="col-md col-12 text-center">
          <!--<div class="section-name wow fadeInRight" data-wow-delay=".2s">People Behind Our Success</div>-->
          <h3
            class="wow fadeInLeft text-capitalize"
            data-aos="fade-right"
            data-aos-delay="300"
          >
            Bienvenido,
          </h3>        
           <p class="intro-description wow fadeInRight" data-aos="fade-right">
            Junta general de vecinos {{convocatoria.comunidad}}
          </p>
        </div>
      </div>
      <div class="row  justify-content-center">
        <div class="col-lg-3 col-md-3 col-sm-12 text-left">
          <div class="box-info-convocatoria"> 
            <p>Fecha de celebración:</p>
            <span>{{convocatoria.fecha}}</span>
          </div>   
        </div>   
       <div class="col-lg-3 col-md-3 col-sm-12 text-left">
         <div class="box-info-convocatoria"> 
            <p>Primera convocatoria:</p>
            <span>{{convocatoria.primeraConvocatoria}}</span>
          </div>   
        </div> 
        <div class="col-lg-3 col-md-3 col-sm-12 text-left">
          <div class="box-info-convocatoria"> 
            <p>Segunda convocatoria:</p>
            <span>{{convocatoria.segundaConvocatoria}}</span>
          </div>   
        </div> 
        <div class="col-lg-3 col-md-3 col-sm-12 text-left">
          <div class="box-info-convocatoria"> 
            <p>Lugar de celebración:</p>
            <span>{{convocatoria.lugar}}</span>
          </div>   
        </div>      
      </div>
    </div>
  </section>    
    <section class="section section-md sectionWithOutMargin">  
      <loading v-model:active="loading" :is-full-page="true"></loading>  
      <div class="container">
        <div class="row">
          <div v-if="isPropietarioRepresentado == false" class="col-md col-12 text-center">        
              <puntos-convocatoria-info  @set-propietario-representado ="(n) => {idRepresentante=n; isPropietarioRepresentado = false}" :convocatoriaId="convocatoriaId" :code="code" :email="email"/>
          </div>
          <div v-if="isPropietarioRepresentado == true" class="col-md col-12 text-center">         
            <img class="imgWarning"
      :src="require('../../assets/images/atencion.png')"/>
            <p><span class="color-primary">Atención</span> Otro usuario le esta representando en esta convocatoria.</p>
            <p>No podrá realizar votaciones hasta que no retire la representación establecida</p>
              <button
                  title="Retirar representación"
                  class="button btn btn-warning"
                  type="button"  
                  @click="onSubmitRetirarRepresentacion"             
                >
                Retirar representación
              </button>
          </div>
        </div>
    </div>
    </section>    
</template>

<script>
import apiService from "@/api/Api.service";
import Loading from "vue-loading-overlay";
import moment from "moment";
import PuntosConvocatoriaInfo from "./PuntosConvocatoriaInfo";

export default {
  name: "JuntaVirutalInfo",
   components: {
    Loading,
    PuntosConvocatoriaInfo    
  },
  data() {
    return {
      isPropietarioRepresentado: false,
      loading:false,
      convocatoria: new Object(),
      idRepresentante:null
    };
  },
  props: ["convocatoriaId", "code", "email"],
  mounted(){
    let data = {
        Email: this.email,
        Code: this.code,
        ConvocatoriaId:this.convocatoriaId
    };
    this.loading = true;
    apiService.getMeeting(this.convocatoriaId).then((responseMeeting) => {
       if (responseMeeting.status == 200 && responseMeeting.data !== null) {
          this.convocatoria.comunidad = responseMeeting.data.comunidad;
          var fecha= moment(responseMeeting.data.fecha)
          this.convocatoria.fecha = moment(responseMeeting.data.fecha).format("DD/MM/YYYY");
          this.convocatoria.primeraConvocatoria = fecha.format("HH:mm");
          this.convocatoria.segundaConvocatoria = fecha.add(responseMeeting.data.tiempoEntreCelebraciones,'minutes').format("HH:mm");
          this.convocatoria.lugar = responseMeeting.data.lugar;       
          
          apiService.isPropietarioRepresentado(data).then((response)=>{
              this.isPropietarioRepresentado = response.data.isRepresentado;
              if(response.data.isRepresentado){
                  console.log();
                  this.idRepresentante = response.data.idRepresentante;
              }
              this.loading = false;
          });   
       }
    });     
  },
  methods: {
    onSubmitRetirarRepresentacion(){
        console.log("onSubmitRetirarRepresentacion");
        apiService.disableRepresentante(this.idRepresentante).then((response)=>{
          if(response.data == true){
              this.isPropietarioRepresentado = false;
          }
        });
      }
    },
    setRepresentante(idRepre){
      alert(idRepre);
      //this.isPropietarioRepresentado = true;
      //this.idRepresentante = idRepre;
    }

};
</script>

