<template>
  <section class="section section-intro context-dark">
    <div class="intro-bg"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <h1 class="font-weight-bold wow fadeInLeft" data-aos="fade-right">
            Representación
          </h1>
        </div>
      </div>
    </div>
  </section>
  <section class="section section-md">
    <loading v-model:active="loading" :is-full-page="true"></loading>
    <div class="container">
      <!--RD Mailform-->
      <div class="row justify-content-center">
        <div class="col-xl-8 col-md-8 col-12">
          <div
            v-if="errorMessage.length > 0"
            class="alert alert-danger"
            role="alert"
          >
            {{ errorMessage }}
          </div>
          <div
            v-if="successMessage.length > 0"
            class="alert alert-success"
            role="alert"
          >
            {{ successMessage }}
          </div>
          <h4 v-if="successMessage.length <= 0">
            Cumplimente este formulario para establecer un representante para la
            convocatoria
            {{ infoConvocatoria }}
          </h4>
          <form
            v-if="successMessage.length <= 0"
            class="rd-mailform text-left"
            style="margin-top: 30px"
            method="post"
          >
            <div class="form-wrap">
              <input
                placeholder="Código de votación *"
                title="Código de votación"
                class="form-input input2"
                id="contact-code"
                type="text"
                name="code"
                readonly
                v-model="code"
              />
            </div>
            <div class="form-wrap">
              <p style="font-weight: bold; color: #d3052c">
                Indique nombre, apellidos y NIF de la persona que le
                representará en esta junta
              </p>
            </div>
            <div class="form-wrap">
              <input
                placeholder="Nombre y apellidos del representante *"
                title="Nombre y apellidos del representante"
                class="form-input input2"
                id="contact-name"
                type="text"
                name="name"
                required
                v-model="name"
              />
            </div>
            <div class="form-wrap">
              <input
                placeholder="Email del representante*"
                title="Email del representante*"
                class="form-input input2"
                id="contact-email"
                type="email"
                name="email"
                v-model="email"
                required="true"
              />
            </div>
            <div class="form-wrap">
              <input
                placeholder="NIF representante"
                title="NIF representante"
                class="form-input input2"
                id="contact-nif"
                type="text"
                name="nif"
                v-model="nif"
              />
            </div>
            <div class="form-wrap">
              <p style="font-weight: bold; color: #d3052c">
                Confirme que otorga permisos a su representante para votar en su
                nombre
              </p>
              <label
                class=""
                for="permisos-checkbox"
                @click="acceptPermissions = !acceptPermissions"
              >
                <input
                  class="checkbox"
                  id="permisos-checkbox"
                  type="checkbox"
                  style="margin-right: 10px"
                  name="permisos"
                  required
                  v-model="acceptPermissions"
                />
                <span class="checkbox-label"
                  >Confirmo permisos <span class="req-symbol">*</span></span
                >
              </label>
            </div>

            <div class="form-button group-sm text-center text-lg-left">
              <button
                title="Completa el formulario para poder enviar los datos con este botón"
                class="button button-primary"
                type="button"
                :disabled="
                  loading ||
                  successMessage ||
                  errorMessage ||
                  code.length < 3 ||
                  name.length < 3 ||
                  !acceptPermissions
                "
                @click="onSubmit"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import Vue from "vue";
import router from "../router/index";
import apiService from "@/api/Api.service";
import Loading from "vue-loading-overlay";

//import { VueReCaptcha } from "vue-recaptcha-v3";

//Vue.use(VueReCaptcha, { siteKey: "6LfZlSETAAAAAC5VW4R4tQP8Am_to4bM3dddxkEt" });

export default {
  name: "RepresentanteFormPage",
  components: {
    Loading,
  },
  data() {
    return {
      convocatoriaId: this.$route.params.convocatoriaId,
      code: this.$route.params.CodigoVotante,
      email: "",
      name: "",
      nif: "",
      acceptConditions: false,
      acceptPermissions: false,
      loading: false,
      errorMessage: "",
      successMessage: "",
      infoConvocatoria: "",
    };
  },
  mounted() {
    console.log("mounted", this.convocatoriaId);
    if (!this.isUUID(this.convocatoriaId)) {
      router.push("/");
    } else {
      apiService.getMeeting(this.convocatoriaId).then((response) => {
        try {
          if (
            response.status == 200 &&
            typeof response.data === "object" &&
            !Array.isArray(response.data) &&
            response.data !== null
          ) {
            const result = response.data;
            const objDate = new Date(result.fecha);
            const zeroPad = (num, places) => String(num).padStart(places, "0");
            const fechaString =
              objDate.getDate() +
              "/" +
              zeroPad(objDate.getMonth() + 1, 2) +
              "/" +
              objDate.getFullYear() +
              " " +
              zeroPad(objDate.getHours()) +
              ":" +
              zeroPad(objDate.getMinutes());
            this.infoConvocatoria =
              "'" + result.razon + "' (" + fechaString + ")";
          } else {
            this.errorMessage =
              "Error verificando convocatoria " +
              this.convocatoriaId +
              ". Detalles: " +
              String(response) +
              "";
            console.error("error quering meeting then result", response);
            setTimeout(() => {
              router.push("/");
            }, 5000);
          }
        } catch (e) {
          console.error("getMeeting then result", e);
          router.push("/");
        }
      });
    }
  },
  methods: {
    isUUID(uuid) {
      let s = "" + uuid;
      s = s.match(
        "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
      );
      if (s === null) {
        return false;
      }
      return true;
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validateForm() {
      this.errorMessage = "";
      if (this.convocatoriaId.length < 3) {
        this.errorMessage = "Falta información de la convocatoria.";
        return false;
      }
      if (this.code.trim().length < 3) {
        this.errorMessage = "El código debe tener más caracteres.";
        return false;
      }
      if (this.name.trim().length < 3) {
        this.errorMessage = "El nombre debe tener más caracteres.";
        return false;
      }
      /*if (this.nif.trim().length < 6) {
        this.errorMessage = "El nif debe tener más caracteres.";
        return false;
      }*/
      if (!this.validateEmail(this.email.trim())) {
        this.errorMessage = "El email no es válido.";
        return false;
      }
      return true;
    },
    onSubmit() {
      if (!this.validateForm()) return;
      this.successMessage = "";
      /*
        public Guid ConvocatoriaId { get; set; }
        public string CodigoVotante { get; set; }
        public string NombreCompleto { get; set; }
        public string Email { get; set; }
        public string Nif { get; set; }
         */
      let data = {
        ConvocatoriaId: this.convocatoriaId,
        CodigoVotante: this.code.trim(),
        NombreCompleto: this.name.trim(),
        Email: this.email.trim(),
        Nif: this.nif ? this.nif.trim() : "",
      };
      console.log("data", data);

      //this.$recaptcha("login").then(token => {
      //  data["g-recaptcha-response"] = token;
      this.loading = true;
      apiService.createRepresentante(data).then((response) => {
        //console.log("createRepresentante then response ", response);
        if (
          response.status == 200 ||
          response.status == 201 ||
          response.status == 204
        ) {
          console.log("createRepresentante then OK status ", response);
          //this.$toastr.success("saved");
          this.successMessage =
            "El registro de representación se ha enviado satisfactoriamente!";
          window.scrollTo(0, 150);
        } else {
          //por la configucaion de axios, aquí nos viene el mensaje de error ya como string en el response
          console.warn("createRepresentante no valid response ", response);
          //this.$toastr.error("Error al guardar");
          this.errorMessage =
            "Ha ocurrido un error al guardar representante!. " +
            String(response);
        }

        this.loading = false;
      });
      /*.catch((err) => {//los catch no son necesarios, usamos configuracion de Axios
          this.loading= false;
          console.warn("createRepresentante catch err", err);
          this.errorMessage  = 'Ha ocurrido un error al guardar!. Intentalo más tarde por favor. '+String(err);

        })*/
      //});
    },
    // async recaptcha() {
    //   // (optional) Wait until recaptcha has been loaded.
    //   await this.$recaptchaLoaded();

    //   // Execute reCAPTCHA with action "login".
    //   const token = await this.$recaptcha("login");
    //   console.log(token);

    //   // Do stuff with the received token.
    // },
    /*
    recaptcha() {
      console.log("recaptcha clicked");
      this.$recaptchaLoaded().then(() => {
        console.log("recaptcha loaded");
        this.$recaptcha("login").then((token) => {
          console.log(token); // Will print the token
        });
      });
    },*/
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-input {
  padding: 12px;
}
button[disabled] {
  cursor: not-allowed;
}
.checkbox {
  width: 20px;
  height: 20px;
}
.checkbox-label {
  vertical-align: super;
}
</style>
