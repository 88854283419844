<template>
  <section class="section main-section section-intro context-dark">
    <div class="intro-bg"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <h1 class="font-weight-bold wow fadeInLeft" data-aos="fade-right">
            Preguntas frecuentes
          </h1>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container paddin-80">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <div id="accordion">
            <div class="card">
              <div class="card-header" id="headingOne">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                  >
                    Recorrido general plataforma
                  </button>
                </h5>
              </div>

              <div
                id="collapseOne"
                class="collapse"
                aria-labelledby="headingOne"
                data-parent="#accordion"
              >
                <div class="card-body">
                  Un repaso general por la plataforma para conocer la
                  estructura, el funcionamiento y los servicios disponibles en
                  cada sección
                </div>
                <div class="video-responsive">
                  <iframe src="https://www.youtube.com/embed/eDrbkwjrGYo" />
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Cómo realizar las votaciones
                  </button>
                </h5>
              </div>

              <div
                id="collapseTwo"
                class="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordion"
              >
                <div class="card-body">
                  En este video vamos a ver los distintos sistemas de votación
                  que permite la plataforma de "Tu Despacho Digital" en la parte
                  de gestión de juntas y cómo administrarlos. VHS.
                </div>
                <div class="video-responsive">
                  <iframe src="https://www.youtube.com/embed/hfSw-Bewz0w" />
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingThree">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    Cómo crear preguntas adicionales en un punto del día
                  </button>
                </h5>
              </div>
              <div
                id="collapseThree"
                class="collapse"
                aria-labelledby="headingThree"
                data-parent="#accordion"
              >
                <div class="card-body">
                  En este video veremos como crear preguntas adicionales en un
                  punto del día durante el desarrollo de una junta de vecinos.
                </div>
                <div class="video-responsive">
                  <iframe src="https://www.youtube.com/embed/wvBg5Uzd9GQ" />
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingFour">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    Cómo generar las actas de una junta celebrada
                  </button>
                </h5>
              </div>
              <div
                id="collapseFour"
                class="collapse"
                aria-labelledby="headingFour"
                data-parent="#accordion"
              >
                <div class="card-body">
                  En este video vamos a ver cómo se generan los documentos de
                  acta de la junta una vez celebrada. En el proceso se combinan
                  la automatización con la flexibilidad para realizar cambios en
                  el documento final.
                </div>
                <div class="video-responsive">
                  <iframe src="https://www.youtube.com/embed/ep7EQH2p4Oo" />
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingFive">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    Cómo gestionar una junta
                  </button>
                </h5>
              </div>
              <div
                id="collapseFive"
                class="collapse"
                aria-labelledby="headingFive"
                data-parent="#accordion"
              >
                <div class="card-body">
                  En este video vemos como iniciar una junta virtual y cómo
                  llevar el control de juntas tanto virtuales como presenciales
                  desde el panel de control de juntas de la aplicación,
                  automatizando el registro de asistencia, el cálculo de
                  votaciones y la generación de acta.
                </div>
                <div class="video-responsive">
                  <iframe src="https://www.youtube.com/embed/aUZI9erTySM" />
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingSix">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    Cómo enviar el código de votación a un propietario
                  </button>
                </h5>
              </div>
              <div
                id="collapseSix"
                class="collapse"
                aria-labelledby="headingSix"
                data-parent="#accordion"
              >
                <div class="card-body">
                  En este vídeo veremos cómo enviar un código de votación
                  durante la celebración de una junta.
                </div>
                <div class="video-responsive">
                  <iframe src="https://www.youtube.com/embed/Xapxm6m6qJE" />
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingSeven">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    Cómo gestionar puntos del día que requieren Quorum
                  </button>
                </h5>
              </div>
              <div
                id="collapseSeven"
                class="collapse"
                aria-labelledby="headingSeven"
                data-parent="#accordion"
              >
                <div class="card-body">
                  En este video veremos como se crean y gestionan puntos del día
                  de una junta que requieren un quorum específico para su
                  aprobación.
                </div>
                <div class="video-responsive">
                  <iframe src="https://www.youtube.com/embed/l1JCoNUqIlY" />
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingEight">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseEight"
                    aria-expanded="false"
                    aria-controls="collapseEight"
                  >
                    Cómo gestionar comunidades
                  </button>
                </h5>
              </div>
              <div
                id="collapseEight"
                class="collapse"
                aria-labelledby="headingEight"
                data-parent="#accordion"
              >
                <div class="card-body">
                  En este video veremos como se gestionan las distintas
                  comunidades de un cliente, la gestión de propietarios y toda
                  la información asociada.
                </div>
                <div class="video-responsive">
                  <iframe src="https://www.youtube.com/embed/BKIzu3v_7Qw" />
                </div>
              </div>
            </div>
            <div class="card">
              <div class="card-header" id="headingNine">
                <h5 class="mb-0">
                  <button
                    class="btn btn-link collapsed"
                    data-toggle="collapse"
                    data-target="#collapseNine"
                    aria-expanded="false"
                    aria-controls="collapseNine"
                  >
                    Cómo gestionar convocatorias
                  </button>
                </h5>
              </div>
              <div
                id="collapseNine"
                class="collapse"
                aria-labelledby="headingNine"
                data-parent="#accordion"
              >
                <div class="card-body">
                  En este video veremos como crear convocatorias, notificar a
                  los propietarios y realizar el control de las juntas tanto
                  virtuales como presenciales.
                </div>
                <div class="video-responsive">
                  <iframe src="https://www.youtube.com/embed/ml6UK_jz9kg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "FAQPropietariosPage",

  data() {
    return {
      text: `
          Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry
          richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor
          brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon
          tempor, sunt aliqua put a bird on it squid single-origin coffee nulla
          assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore
          wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher
          vice lomo. Leggings occaecat craft beer farm-to-table, raw denim aesthetic
          synth nesciunt you probably haven't heard of them accusamus labore VHS.
        `,
    };
  },
  mounted() {},
  created() {},
  methods: {},
};
</script>

<style scoped>
a {
  text-decoration: underline;
}
a:hover {
  color: red;
}

.video-responsive {
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  padding-top: 30px;
  position: relative;
  margin: 2em;
}
.video-responsive iframe,
.video-responsive object,
.video-responsive embed {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.paddin-80 {
  padding: 80px 0;
}
</style>
