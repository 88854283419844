<template>     
    <Modal modalClass="modalVotacion" class="modalVotacion"  v-model:visible="isVisibleModalVotacion" title="" >  
        {{messageVotacion}}        
    </Modal>
    <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 text-left">
            <p class="color-primary">Punto {{numeroPunto}} - {{ this.puntoConvocatoriaInfo.titulo }} </p>  
            <span > {{ this.puntoConvocatoriaInfo.descripcion ? this.puntoConvocatoriaInfo.descripcion : "" }} </span>      
      
        </div>                                   
    </div> 
    <div class="puntoConvocatoriaVotacion" v-if="this.puntoConvocatoriaInfo.isPregunta || this.puntoConvocatoriaInfo.tipoId < 3">
        <div  class="row rowWhithOutMargin">
            <div class="col-lg-6 col-md-6 col-sm-12">
                <span v-if="codigosVotacion.length > 1" style="color:#d3052c"> Todas </span>
                <span v-if="codigosVotacion.length == 1" style="color:#d3052c"> &nbsp; </span>
            </div>
            <div class="col-lg-6 col-md-6 col-sm-12">
                <div class="row">
                    <div v-for="(tipo, iTipo) in this.tipoRespuestas" :key="iTipo" class="col-lg-4 col-md-4 col-sm-12 text-right votacionPadre">
                        <label :for="'Tipo_' + iTipo" class="ml-2">{{tipo.displayName}} </label> 
                            <input :disabled="isDisabledPadre" :checked="this.puntoConvocatoriaInfo.votacionPadre == tipo.id" type="radio" :class="'radio_' + tipo.name" :name="'votacionPadre_' + this.puntoConvocatoriaInfo.id" @change="this.changeVotacionPadre(tipo.id)" :value="tipo.id" >
                    </div>                                  
                </div>                              
            </div>
        </div>
        <div v-if="(toggle)" class="desgloseVotaciones">                       
            <div  v-for="(codigo, iCodigo) in codigosVotacion" class="row rowWhithOutMargin" :key="iCodigo">
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <span style="color:#d3052c"> {{codigo.fincas}} </span>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12">
                    <div class="row">                                
                        <div v-for="(tipo, iTipo) in tipoRespuestas" :key="iTipo" class="col-lg-4 col-md-4 col-sm-12 text-right">
                            <label :for="'Tipo_' + iTipo" class="ml-2">{{tipo.displayName}} </label> 
                            <input :disabled="isDisabledVotacion"  :class="'radio_' + tipo.name" type="radio" v-model="this.puntoVotacion.parent_codigo[iCodigo]"  :name="'votacion_' + this.puntoConvocatoriaInfo.id + '_' + codigo.codigo" :value="tipo.id">
                        </div>                                  
                    </div>
                </div>                                  
            </div>
        </div>
        <div v-if="this.nCodigosVotacion > 1" class="row rowWhithOutMargin">
            <div class="col-lg-6 col-md-6 col-sm-12 text-right"></div>
            <div class="col-lg-6 col-md-6 col-sm-12 text-right">
                <a class="help" href="javascript:void(0)" @click="showVotaciones" >Desglose de votaciones</a>
            </div>
        </div>
        <div class="row rowWhithOutMargin">
            <div class="col-lg-9 col-md-9 col-sm-12 text-right"></div>
            <div class="col-lg-3 col-md-3 col-sm-12 text-right">
                <button
                    title="Enviar votación"
                    class="button button-primary w-100"
                    @click="onClickSendVotacion(this.puntoConvocatoriaInfo.id)"
                    type="button" :disabled="isDisabledVotacion">
                    Enviar votación
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import apiService from "@/api/Api.service";
import { Modal } from 'usemodal-vue3';

export default {
  name: "PuntoConvocatoriaVotacion",
   components: {
        Modal
  },
  props: ['numeroPunto', 'item', 'codigosVotacion','tipoRespuestas','isPregunta','email', 'convocatoriaId'],
  /*props: {  
      codigosVotacion:Array,
      tipoRespuestas:Array      
  },*/
  data() {
    return {
      toggle:false,
      isDisabledPadre:false,
      isDisabledVotacion: false,
      puntoVotacion:{
          parent_codigo:[],
          parent:[]
      },
      puntoConvocatoriaInfo:{
          titulo:"",
          descripcion:"",
          optionSelected:"",
          id:"",
          tipoId:0,
          isPregunta:false,
          votacionPadre:0
      },
      nCodigosVotacion:0,
      messageVotacion:"",
      isVisibleModalVotacion:false,
    }
  },
  mounted(){
        this.puntoConvocatoriaInfo.titulo = this.item.titulo;
        this.puntoConvocatoriaInfo.descripcion = this.item.descripcion;
        this.puntoConvocatoriaInfo.tipoId = this.item.tipoId;
        this.puntoConvocatoriaInfo.id = this.item.id;
        this.puntoConvocatoriaInfo.isPregunta = this.isPregunta;
        this.nCodigosVotacion = this.codigosVotacion != null ? this.codigosVotacion.length : 0;
        this.isDisabledPadre = this.item.votaciones != null && this.item.votaciones.length > 0 && !this.item.votacionAbierta;
        this.isDisabledVotacion = this.item.votaciones != null && this.item.votaciones.length > 0  && !this.item.votacionAbierta;
        if(this.codigosVotacion != null && this.codigosVotacion.length == 1){   
            if(this.item.votaciones != null && this.item.votaciones[0] != null && this.item.votaciones[0].tipoRespuestaId != null){    
                   this.puntoConvocatoriaInfo.votacionPadre = this.item.votaciones[0].tipoRespuestaId;
            }
        }
        else if(this.codigosVotacion != null && this.codigosVotacion.length > 1){
            for (let index = 0; index < this.codigosVotacion.length; index++) {
                if(this.item.votaciones[index] != null && this.item.votaciones[index].tipoRespuestaId != null){
                    this.puntoVotacion.parent_codigo[index] = this.item.votaciones[index].tipoRespuestaId;            
                }
                else{
                    this.puntoVotacion.parent_codigo[index] = -1;
                }
            }
        }
        console.log("this.puntoVotacion.parent_codigo[index]", this.puntoVotacion.parent_codigo);
  },
  watch: {
    item: function (val) {
        if (val) {
            this.puntoConvocatoriaInfo.titulo = val.titulo;
            this.puntoConvocatoriaInfo.descripcion = val.descripcion;
            this.puntoConvocatoriaInfo.id = val.id;
            this.puntoConvocatoriaInfo.isPregunta = this.isPregunta;
            if(this.codigosVotacion.length == 1){   
                if(this.item.votaciones != null && this.item.votaciones[0] != null && this.item.votaciones[0].tipoRespuestaId != null){    
                   this.puntoConvocatoriaInfo.votacionPadre = this.item.votaciones[0].tipoRespuestaId;
                   console.log(" this.puntoConvocatoriaInfo.votacionPadre",  this.puntoConvocatoriaInfo.votacionPadre);
                }
            }
        }
    },
  /*  codigosVotacion:function (val) {
        if (val) {
             this.nCodigosVotacion =  this.codigosVotacion != null ? this.codigosVotacion.length : 0;
        }
    },*/
    isVisibleModalVotacion:function (val) {
        console.log("val", val);
        if (val == false) {
             this.messageVotacion = "";
        }
    }
  },
  methods: {
    showVotaciones(){
        this.toggle = !this.toggle;
      //  this.isDisabledPadre = this.toggle ? true: this.isDisabledPadre;
    },
    changeVotacionPadre(value){
        console.log("changeVotacionPadre", value);
        for (let index = 0; index < this.codigosVotacion.length; index++) {
            this.puntoVotacion.parent_codigo[index] = value;            
        }
        console.log("this.puntoVotacion.parent_codigo[index]", this.puntoVotacion.parent_codigo);
    },
    onClickSendVotacion(puntoConvocatoriaId){
        if(this.isPregunta == false){
            apiService.isVotacionAbierta(puntoConvocatoriaId).then(resultPuntoPrincipal =>{
                if(resultPuntoPrincipal.data == true){       
                     this.sendVotacion();             
                }
                else{
                    this.messageVotacion = "La votación no esta abierta todavía, espere a que lo haga el administrador";
                    this.isVisibleModalVotacion = true;
                }
            });
        }
        else{          
            apiService.isVotacionAbiertaOfPregunta(puntoConvocatoriaId).then(resultPregunta =>{
                if(resultPregunta.data == true){       
                    this.sendVotacion();
                }
                else{
                    this.messageVotacion = "La votación no esta abierta todavía, espere a que lo haga el administrador";
                    this.isVisibleModalVotacion = true;
                }
            });
        }
    },
    sendVotacion(){
        var data = {
            PuntoConvocatoriaId: this.puntoConvocatoriaInfo.id, 
            Votaciones:[],
            Email:this.email,
            ConvocatoriaId: this.convocatoriaId,
            IsPregunta: this.isPregunta,
        };
        this.codigosVotacion.forEach((element, index) => {                    
            var votacion = {
                TipoRespuestaId: this.puntoVotacion.parent_codigo[index],               
                CodigoVotacion: element.codigo
            }
            data.Votaciones.push(votacion);
        });  
        apiService.updateVotacion(data).then((resultVotacion)=>{
            if(resultVotacion.data.includes("#VOTACIONCORRECTA#")){
                this.messageVotacion = "La votación se ha registrado correctamente."
            }
            else{
                this.messageVotacion = resultVotacion.data;
            }
            this.isVisibleModalVotacion = true;
        })
    },
    isRespuestaSelected(respuestaId, propietarioId, index){
        var result = false;     
        if(this.item.votaciones != null){
            var itemV = this.item.votaciones.filter(v => v.propietarioId == propietarioId);
            if(itemV != null && itemV[0] && itemV[0].tipoRespuestaId == respuestaId){
                result = true;
                if(this.puntoVotacion.parent_codigo[index] == null){
                    this.puntoVotacion.parent_codigo[index] = respuestaId; 
                }
            }
        }
        return result;
    }
  }
}

</script>