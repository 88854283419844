<template>
    <h1>Condiciones Generales Representacion</h1>

    <p>This is a paragraph.</p>


</template>


<script>
    export default {
        name: "CondicionesGeneralesRepresentacionPage",
        data() {
            return {
            }
        },
        methods: {
        }
    }
</script>