<template>
  <nav-menu></nav-menu>
  <router-view />
  <Footer></Footer>
</template>

<script>
import NavMenu from "./components/NavMenu.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "App",
  components: {
    NavMenu,
    Footer,
  },
  created() {
    /*
    var scripts = [
      "assets/js/script.js",
      "assets/js/core.min.js"
    ];
    scripts.forEach(script => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    });*/
  },
};
</script>

<style>
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  font-family: "Montserrat", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 15px;
  line-height: 2.14286;
  text-align: left;
  color: #2c3e50;
}
</style>
