<template>
    <h1 id="tableLabel">Weather forecast</h1>

    <p>This component demonstrates fetching data from the server.</p>

    <p v-if="!items"><em>Loading...</em></p>

    <table class='table table-striped' aria-labelledby="tableLabel" v-if="items">
        <thead>
            <tr>
                <th>key</th>
                <th>nombre</th>
                <th>habilitado</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="item of items" v-bind:key="item">
                <td>{{ forecast.key }}</td>
                <td>{{ forecast.nombre }}</td>
                <td>{{ forecast.habilitado }}</td>
            </tr>
        </tbody>
    </table>
</template>


<script>
    import axios from 'axios'
    export default {
        name: "FetchData",
        data() {
            return {
                items: []
            }
        },
        methods: {
            loadData() {
                axios.get('/api/values')
                    .then((response) => {
                        this.items =  response.data;
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>