import { createWebHistory, createRouter } from "vue-router";
import Home from "@/pages/HomePage.vue";
import Contacto from "@/pages/ContactoFormPage.vue";
import FetchData from "@/pages/FetchData.vue";
import Representante from "@/pages/RepresentanteFormPage.vue";
import AyudaRecursos from "@/pages/AyudaRecursosPage.vue";
import CondicionesGeneralesRepresentacion from "@/pages/CondicionesGeneralesRepresentacionPage.vue";
import NotFound from "@/pages/NotFoundPage.vue";
import PoliticaProteccionDatos from "@/pages/PoliticaProteccionDatosPage.vue";
import AvisoLegal from "@/pages/AvisoLegalPage.vue";
import ModelosCumplimentacion from "@/pages/help/ModelosCumplimentacionPage.vue";
import FAQPropietariosPage from "@/pages/help/FAQPropietariosPage.vue";
import Guias from "@/pages/help/GuiasPage.vue";
import JuntaVirtual from "@/pages/JuntaVirtualPage.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/representante/:convocatoriaId/:CodigoVotante",
    name: "Representante",
    component: Representante,
  },
  {
    path: "/ayuda-recursos",
    name: "AyudaRecursos",
    component: AyudaRecursos,
  },
  {
    path: "/ayuda-recursos/modelos",
    name: "ModelosCumplimentacion",
    component: ModelosCumplimentacion,
  },
  {
    path: "/ayuda-recursos/FAQ",
    name: "FAQPropietariosPage",
    component: FAQPropietariosPage,
  },
  {
    path: "/ayuda-recursos/guias",
    name: "Guias",
    component: Guias,
  },
  {
    path: "/condiciones-representacion",
    name: "CondicionesGeneralesRepresentacion",
    component: CondicionesGeneralesRepresentacion,
  },
  {
    path: "/contacto",
    name: "Contacto",
    component: Contacto,
  },
  {
    path: "/terminos-condiciones-proteccion-datos",
    name: "PoliticaProteccionDatos",
    component: PoliticaProteccionDatos,
  },
  {
    path: "/aviso-legal",
    name: "AvisoLegal",
    component: AvisoLegal,
  },
  {
    path: "/FetchData",
    name: "FetchData",
    component: FetchData,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: NotFound,
  },
  {
    path: "/:pathMatch(.*)",
    name: "BadNotFound",
    component: NotFound,
  },
  {
    path: "/juntavirtual/:convocatoriaId/:CodigoVotante",
    name: "JuntaVirtual",
    component: JuntaVirtual,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
