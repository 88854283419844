<template>
  <section class="section main-section section-intro context-dark">
    <div class="intro-bg"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <h1 class="font-weight-bold wow fadeInLeft" data-aos="fade-left">
            Contacto
          </h1>
          <p class="intro-description wow fadeInRight" data-aos="fade-right">
            Infórmese de como conseguir la solución
          </p>
        </div>
      </div>
    </div>
  </section>
  <!--Mailform-->
  <section class="section section-md">
    <loading v-model:active="loading" :is-full-page="true"></loading>
    <div class="container">
      <!--RD Mailform-->
      <div
        class="row justify-content-center"
        data-aos="fade-down"
        data-aos-delay="100"
      >
        <div class="col-xl-6 col-md-8 col-12">
          <div
            v-if="errorMessage.length > 0"
            class="alert alert-danger"
            role="alert"
          >
            {{ errorMessage }}
          </div>
          <div
            v-if="successMessage.length > 0"
            class="alert alert-success"
            role="alert"
          >
            {{ successMessage }}
          </div>
          <form class="rd-mailform text-left" method="post">
            <div class="form-wrap">
              <input
                placeholder="Nombre *"
                title="Pon aquí tu nombre"
                class="form-input"
                id="contact-name"
                type="text"
                name="name"
                required
                v-model="name"
              />
            </div>
            <div class="form-wrap">
              <input
                placeholder="Teléfono"
                title="Pon aquí tu teléfono de contacto"
                class="form-input"
                id="contact-phone"
                type="text"
                name="phone"
                required
                v-model="phone"
              />
            </div>
            <div class="form-wrap">
              <input
                placeholder="Email"
                title="Tu dirección de correo electrónico"
                class="form-input"
                id="contact-email"
                type="email"
                name="email"
                v-model="email"
              />
            </div>
            <div class="form-wrap">
              <textarea
                placeholder="Mensaje *"
                title="El mensaje que quieres enviarnos"
                class="form-input"
                id="contact-message"
                name="message"
                rows="3"
                v-model="message"
              ></textarea>
            </div>
            <div class="form-wrap">
              <label class="" for="contact-accept">
                <input
                  class="checkbox"
                  id="contact-accept"
                  type="checkbox"
                  style="margin-right: 10px"
                  name="accept"
                  required
                  v-model="acceptConditions"
                  @click="acceptConditions = !acceptConditions"
                />
                <span class="checkbox-label">
                  He leído y acepto la información sobre el tratamiento de datos
                  personales
                </span>
              </label>
            </div>
            <div class="form-button group-sm text-center text-lg-left">
              <button
                title="Completa el formulario para poder enviar los datos con este botón"
                class="button button-primary"
                type="button"
                :disabled="
                  loading ||
                  successMessage ||
                  name.length < 3 ||
                  email.length < 3 ||
                  message.length < 1 ||
                  !acceptConditions
                "
                @click="onSubmit"
              >
                Enviar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
import apiService from "@/api/Api.service";
import Loading from "vue-loading-overlay";
export default {
  name: "ContactoFormPage",
  components: {
    Loading,
  },
  data() {
    return {
      email: "",
      name: "",
      phone: "",
      message: "",
      acceptConditions: false,
      loading: false,
      errorMessage: "",
      successMessage: "",
    };
  },
  mounted() {},
  created() {},
  methods: {
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    resetFormData() {
      this.name = "";
      this.phone = "";
      this.email = "";
      this.message = "";
    },
    validateForm() {
      this.errorMessage = "";
      if (this.name.length < 3) {
        this.errorMessage = "El nombre debe tener más caracteres.";
        return false;
      }
      if (!this.validateEmail(this.email)) {
        this.errorMessage = "El email no es válido.";
        return false;
      }
      if (this.message.length < 1) {
        this.errorMessage = "El Mensaje es campo requerido.";
        return false;
      }
      if (this.phone && isNaN(this.phone)) {
        this.errorMessage = "El Teléfono debe ser un número.";
        return false;
      }
      return true;
    },
    onSubmit() {
      if (!this.validateForm()) return;
      this.successMessage = "";
      let data = {
        Mensaje: this.message.replace(/(?:\r\n|\r|\n)/g, "<br>"),
        Nombre: this.name,
        Email: this.email,
        Telefono: this.phone,
      };

      this.loading = true;
      apiService.contact(data).then((response) => {
        //console.log("createRepresentante then response ", response);
        if (response.status == 200) {
          console.log("contact then OK response ", response);
          //this.$toastr.success("saved");
          this.successMessage =
            "Se ha enviado el mensaje correctamente. Contestaremos al email proporcionado lo antes posible. ¡Gracias!";
          this.resetFormData();
        } else {
          //por la configucaion de axios, aquí nos viene el mensaje de error ya como string en el response
          console.warn("contact no valid response ", response);
          this.errorMessage =
            "Ha ocurrido un error al enviar mensaje el. " + String(response);
        }

        this.loading = false;
      });
      /*.catch((err) => {//los catch no son necesarios, usamos configuracion de Axios
          this.loading= false;
          console.warn("createRepresentante catch err", err);
          this.errorMessage  = 'Ha ocurrido un error al guardar!. Intentalo más tarde por favor. '+String(err);

        })*/
      //});
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-input {
  padding: 12px;
}
button[disabled] {
  cursor: not-allowed;
}
.checkbox {
  width: 20px;
  height: 20px;
}
.checkbox-label {
  vertical-align: super;
}
/*
a {
  text-decoration: underline;
}
a:hover {
  color: red;
}*/
</style>
