<template>
  <section class="section main-section section-intro context-dark">
    <div class="intro-bg"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <h3 class="font-weight-bold wow" data-aos="slide-up">
            Política de protección de datos
          </h3>
        </div>
      </div>
    </div>
  </section>
  <section class="section section-sm bg-default">
    <div class="container">
      <div class="row">
        <div class="main-page-wrapper">
          <p>
            Ponemos a su disposición la presente Política de Privacidad con la
            finalidad de informarle detalladamente sobre cómo tratamos sus datos
            personales y protegemos su privacidad y la información que nos
            proporciona.
          </p>
          <p>
            Si, en el futuro, introdujésemos modificaciones en esta Política de
            Privacidad, se lo comunicaremos a través de esta página web o por
            otros medios, de modo que pueda usted conocer las nuevas condiciones
            de privacidad que se adopten.
          </p>
          <p>
            Le informamos a continuación, en forma de preguntas y respuestas, de
            las condiciones en las que nuestra entidad trata sus datos
            personales:
          </p>
          <h4>
            <strong
              >¿Quién es el responsable del tratamiento de sus datos?</strong
            >
          </h4>
          <p>
            Identidad: EL COLEGIO PROFESIONAL DE ADMINISTRADORES DE FINCAS DE
            MADRID (en adelante, EL COLEGIO).
          </p>
          <ul>
            <li>Domicilio social: C/ García de Paredes, 70, 28010 – Madrid.</li>
            <li>Teléfono: +34 915 919 670.</li>
            <li>
              Correo electrónico:
              <a href="mailto:secretaria@cafmadrid.es"
                >secretaria@cafmadrid.es</a
              >
            </li>
          </ul>
          <h4>
            <strong
              >¿Quién es el Delegado de Protección de Datos (DPD) de la entidad
              y cómo puede ayudarte?</strong
            >
          </h4>
          <p>
            El DPD es una figura, legalmente prevista, que tiene como funciones
            principales las de informar y asesorar a nuestra entidad sobre las
            obligaciones que le afectan en materia de protección de datos
            personales y supervisar su cumplimiento.
          </p>
          <p>
            Además, el DPD actúa como punto de contacto para cualquier cuestión
            relativa al tratamiento de datos personales, por lo que, si tiene
            cualquier consulta, duda o sugerencia en relación con cómo usamos
            sus datos personales, puede usted dirigirse a él
          </p>
          <ul>
            <li>
              Identidad del Delegado de Protección de Datos: Picón y Asociados
              Derecho y Nuevas Tecnologías, S.L
            </li>
            <li>
              Correo electrónico de contacto del DPD:
              <a href="mailto:dpd@piconyasociados.es">dpd@piconyasociados.es</a>
            </li>
          </ul>
          <h4>
            <strong>¿Con qué finalidad tratamos sus datos personales?</strong>
          </h4>
          <p>
            Tratamos los datos personales que nos facilite con la finalidad de
            dar respuesta a las consultas formuladas por los usuarios y hacer un
            seguimiento posterior. El hecho de facilitar los datos con este fin
            es voluntario, aunque, en caso de no hacerlo, no se podrá responder
            a la consulta formulada. Por tanto, la comunicación de sus datos
            personales a estos efectos es un requisito necesario para que
            podamos atenderlas.
          </p>

          <h4><strong>¿Durante cuánto tiempo trataremos sus datos?</strong></h4>
          <p>
            Sólo conservamos sus datos por el periodo de tiempo necesario para
            cumplir con la finalidad para la que fueron recogidos, dar
            cumplimiento a las obligaciones legales que nos vienen impuestas y
            atender las posibles responsabilidades que pudieran derivar del
            cumplimiento de la finalidad para la que los datos fueron recabados.
          </p>
          <p>
            Los datos tratados para atender las consultas formuladas serán
            conservados durante el tiempo necesario para dar respuesta a ellas y
            darlas por definitivamente cerradas. Posteriormente, serán
            conservados a modo de histórico de comunicaciones durante un plazo
            de un año, salvo que usted solicite su supresión antes.
          </p>

          <h4>
            <strong
              >¿Cuál es la legitimación para el tratamiento de sus
              datos?</strong
            >
          </h4>
          <p>
            La base del tratamiento es su consentimiento. En concreto, las
            categorías de datos personales cuyo tratamiento se basa en su
            consentimiento son las siguientes: Datos identificativos y de
            contacto. El consentimiento puede revocarse en cualquier momento,
            pudiendo comunicárnoslo por cualquier medio. La retirada del
            consentimiento no afectará a la licitud de los datos tratados con
            anterioridad.
          </p>

          <h4>
            <strong>¿A qué destinatarios se comunicarán sus datos?</strong>
          </h4>
          <p>
            Los datos no serán comunicados a terceros, salvo aquellas cesiones
            que deben hacerse por imponerlo la legislación vigente. Aunque no se
            trata de una cesión de datos, puede ser que terceras empresas, que
            actúan como proveedores nuestros, accedan a su información para
            llevar a cabo el servicio. Estos encargados acceden a sus datos
            siguiendo nuestras instrucciones y sin que puedan utilizarlos para
            una finalidad diferente y manteniendo la más estricta
            confidencialidad y en base a un contrato en el que se comprometen a
            cumplir las exigencias de la vigente normativa en materia de
            protección de datos personales.
          </p>
          <h4>
            <strong
              >¿Cuáles son sus derechos cuando nos facilita sus datos?</strong
            >
          </h4>
          <p>
            Cualquier persona tiene derecho a obtener confirmación sobre si
            estamos tratando datos personales que le conciernan, o no. Las
            personas interesadas tienen derecho a acceder a sus datos
            personales, así como a solicitar la rectificación de los datos
            inexactos o, en su caso, solicitar su supresión cuando, entre otros
            motivos, los datos ya no sean necesarios para los fines para los que
            fueron recogidos.
          </p>
          <p>
            En las condiciones previstas en el Reglamento General de Protección
            de Datos, los interesados podrán solicitar la limitación del
            tratamiento de sus datos o su portabilidad, en cuyo caso únicamente
            los conservaremos para el ejercicio o la defensa de reclamaciones.
          </p>
          <p>
            En determinadas circunstancias y por motivos relacionados con su
            situación particular, los interesados podrán oponerse al tratamiento
            de sus datos. Si usted ha otorgado el consentimiento para alguna
            finalidad específica, tiene derecho a retirarlo en cualquier
            momento, sin que ello afecte a la licitud del tratamiento basado en
            el consentimiento previo a su retirada. En estos supuestos dejaremos
            de tratar los datos o, en su caso, dejaremos de hacerlo para esa
            finalidad en concreto, salvo por motivos legítimos imperiosos, o el
            ejercicio o la defensa de posibles reclamaciones.
          </p>
          <p>
            Además, la normativa en materia de protección de datos permite que
            pueda oponerse a ser objeto de decisiones basadas únicamente en el
            tratamiento automatizado de sus datos, cuando proceda.
          </p>
          <p>Los antedichos derechos se caracterizan por lo siguiente:</p>
          <ul>
            <li>
              • Su ejercicio es gratuito, salvo que se trate de solicitudes
              manifiestamente infundadas o excesivas (p. ej., carácter
              repetitivo), en cuyo caso podrá cobrarse un canon proporcional a
              los costes administrativos soportados o negarse a actuar
            </li>
            <li>
              • Puede ejercer los derechos directamente o por medio de tu
              representante legal o voluntario
            </li>
            <li>
              • Se debe responder a su solicitud en el plazo de un mes, aunque,
              si se tiene en cuenta la complejidad y número de solicitudes, se
              puede prorrogar el plazo en otros dos meses más.
            </li>
            <li>
              • Tenemos la obligación de informarle sobre los medios para
              ejercitar estos derechos, los cuales deben ser accesibles y sin
              poder denegarle el ejercicio del derecho por el solo motivo de
              optar por otro medio. Si la solicitud se presenta por medios
              electrónicos, la información se facilitará por estos medios cuando
              sea posible, salvo que nos solicite que sea de otro modo.
            </li>
            <li>
              • Si, por cualquier motivo, no se diese curso a la solicitud, le
              informaremos, a más tardar en un mes, de las razones de ello y de
              la posibilidad de reclamar ante una Autoridad de Control
            </li>
          </ul>
          <p>
            A fin de facilitar el ejercicio de los referidos derechos, le
            facilitamos a continuación los enlaces al formulario de solicitud de
            cada uno de ellos:
          </p>
          <ol style="list-style-type: circle">
            <li>
              <a
                href="https://www.aepd.es/media/formularios/formulario-derecho-de-acceso.pdf"
                ><span>Formulario ejercicio del derecho de acceso</span></a
              >
            </li>
            <li>
              <a
                href="https://www.aepd.es/media/formularios/formulario-derecho-de-rectificacion.pdf"
                ><span
                  >Formulario de ejercicio del derecho de
                  rectificaci&oacute;n</span
                ></a
              >
            </li>
            <li>
              <a
                href="https://www.aepd.es/media/formularios/formulario-derecho-de-oposicion.pdf"
                ><span
                  >Formulario de ejercicio del derecho de oposici&oacute;n</span
                ></a
              >
            </li>
            <li>
              <a
                href="https://www.aepd.es/media/formularios/formulario-derecho-de-supresion.pdf"
                ><span
                  >Formulario de ejercicio del derecho de supresi&oacute;n
                  (derecho &ldquo;al olvido&rdquo;)</span
                ></a
              >
            </li>
            <li>
              <a
                href="https://www.aepd.es/media/formularios/formulario-derecho-de-limitacion.pdf"
                ><span
                  >Formulario de ejercicio del derecho a la limitaci&oacute;n
                  del tratamiento</span
                ></a
              >
            </li>
            <li>
              <a
                href="https://www.aepd.es/media/formularios/formulario-derecho-de-portabilidad.pdf"
                ><span
                  >Formulario de ejercicios del derecho a la portabilidad</span
                ></a
              >
            </li>
            <li>
              <a
                href="https://www.aepd.es/media/formularios/formulario-derecho-de-oposicion-decisiones-automatizadas.pdf"
                ><span
                  >Formulario de ejercicio a no ser objeto de decisiones
                  individuales automatizadas</span
                ></a
              >
            </li>
          </ol>
          <p>
            Todos los derechos mencionados pueden ejercerse a través de los
            medios de contacto que figuran al principio de esta cláusula.
          </p>
          <p>
            En todos los casos, deberá acreditar su identidad acompañando
            fotocopia o copia escaneada, de su DNI o documento equivalente, o
            bien documento acreditativo de la representación, si el derecho se
            ejerce mediante representante.
          </p>
          <p>
            Todos los derechos mencionados pueden ejercerse a través de los
            medios de contacto con la entidad que figuran al principio de esta
            cláusula
          </p>
          <p>
            Frente a cualquier vulneración de sus derechos, especialmente cuando
            usted no haya obtenido satisfacción en su ejercicio, puede presentar
            una reclamación ante la Agencia Española de Protección de Datos
            (datos de contacto accesibles en www.aepd.es), u otra autoridad de
            control competente. También puede obtener más información sobre los
            derechos que le asisten dirigiéndose a dichos organismos.
          </p>
          <h4>
            <strong>¿Cómo protegemos sus datos personales?</strong>
          </h4>
          <p>
            Tenemos el firme compromiso de proteger los datos personales que
            tratamos. Utilizamos medidas, controles y procedimientos de carácter
            físico, organizativo y tecnológico, razonablemente fiables y
            efectivos, orientados a preservar la integridad y la seguridad de
            sus datos y garantizar su privacidad.
          </p>
          <p>
            Además, todo el personal con acceso a los datos personales ha sido
            formado y tiene conocimiento de sus obligaciones con relación a los
            tratamientos de sus datos personales.
          </p>
          <p>
            En el caso de los contratos que suscribimos con nuestros proveedores
            incluimos cláusulas en las que se les exige mantener el deber de
            secreto respecto a los datos de carácter personal a los que hayan
            tenido acceso en virtud del encargo realizado, así como implantar
            las medidas de seguridad técnicas y organizativas necesarias para
            garantizar la confidencialidad, integridad, disponibilidad y
            resiliencia permanentes de los sistemas y servicios de tratamiento
            de los datos personales.
          </p>
          <p>
            Todas estas medidas de seguridad son revisadas de forma periódica
            para garantizar su adecuación y efectividad.
          </p>
          <p>
            Sin embargo, la seguridad absoluta no se puede garantizar y no
            existe ningún sistema de seguridad que sea impenetrable por lo que,
            en el caso de cualquier información objeto de tratamiento y bajo
            nuestro control se viese comprometida como consecuencia de una
            brecha de seguridad, tomaremos las medidas adecuadas para investigar
            el incidente, notificarlo a la Autoridad de Control y, en su caso, a
            aquellos usuarios que se hubieran podido ver afectados para que
            tomen las medidas adecuadas.
          </p>
          <h4>
            <strong
              >¿Cuál es su responsabilidad como titular de los datos?
            </strong>
          </h4>
          <p>
            Al facilitarnos sus datos personales, la persona que lo haga
            garantiza que es mayor de 14 años y que los datos facilitados son
            verdaderos, exactos, completos y actualizados.
          </p>
          <p>
            A estos efectos, el interesado responde de la veracidad de los datos
            y los deberá mantener convenientemente actualizados de modo que
            respondan a su situación real, haciéndose responsable de los datos
            falsos e inexactos que pudiera proporcionar, así como de los daños y
            perjuicios, directos o indirectos, que pudieran derivarse.
          </p>
          <p>
            Si usted facilita datos de terceros, asume la responsabilidad de
            informarles previamente de todo lo previsto en el artículo 14 del
            Reglamento General de Protección de Datos en las condiciones
            establecidas en dicho precepto.
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PoliticaProteccionDatosPage",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  margin-top: 15px;
}
@media (min-width: 992px) {
  .section-intro {
    padding: 120px 0 20px 0;
  }
}
</style>
