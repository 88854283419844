<template>
  <section class="section main-section section-intro context-dark">
    <div class="intro-bg"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <h1 class="font-weight-bold wow fadeInLeft">Guías y tutoriales</h1>
          <p class="intro-description wow fadeInRight">
            Familiarícese con el uso de la solución
          </p>
        </div>
      </div>
    </div>
  </section>
  <!--Base typography -->
  <section class="section section-lg bg-default">
    <div class="container">
      <div class="row row-50 flex-lg-row-reverse">
        <div class="col-xl-4">
          <ul class="list-md">
            <li>
              <h4>Columna lateral con propósito no definido</h4>
            </li>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </p>
          </ul>
        </div>
        <div class="col-xl-8">
          <ul class="list-xl">
            <li>
              <h3>Guía de acceso a la plataforma</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <a
                class="button-width-190 button-primary button-circle button-lg button offset-top-30"
                href="#"
                >Leer más</a
              >
            </li>
            <li>
              <h3>Iniciar una junta telemática desde Teams</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat.
              </p>
              <a
                class="button-width-190 button-primary button-circle button-lg button offset-top-30"
                href="#"
                >Leer más</a
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "GuiasPage",
  data() {
    return {};
  },
  mounted() {},
  created() {},
  methods: {},
};
</script>

<style scoped>
a {
  text-decoration: underline;
}
a:hover {
  color: red;
}
</style>
