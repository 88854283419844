<template>  
    <section class="section section-md sectionWithOutMargin puntosConvocatoriaInfo">
        <div
                v-if="successMessage.length > 0"
                class="alert alert-success"
                role="alert"
            >
            {{ successMessage }}
        </div>
        <Modal class="modalPuntoConvocatoria" v-model:visible="isVisibleModal" title="Nombrar representante" :okButton="{ text: 'Enviar', onclick: () => {this.onSubmitRepresentante();}, loading: false}">
                <div
                v-if="errorMessage.length > 0"
                class="alert alert-danger"
                role="alert"
            >
                {{ errorMessage }}
            </div>            
             <div class="form-wrap">
              <input
                placeholder="Nombre y apellidos del representante *"
                title="Nombre y apellidos del representante"
                class="form-input input2"
                id="contact-name"
                type="text"
                name="name"
                required
                v-model="representanteName"
              />
            </div>
            <div class="form-wrap">
              <input
                placeholder="Email del representante *"
                title="Email del representante"
                class="form-input input2"
                id="contact-email"
                type="email"
                name="email"
                v-model="representanteEmail"
              />
            </div>
            <div class="form-wrap">
              <input
                placeholder="NIF representante"
                title="NIF representante"
                class="form-input input2"
                id="contact-nif"
                type="text"
                name="nif"
                v-model="representanteNif"
              />
            </div>             
        </Modal>
        <div v-if="this.representanteId == null" class="container">
             <loading v-model:active="loading" :is-full-page="true"></loading>  
            <div class="row">
                <div class="col-md col-12 text-left font-weight-bold">
                    Fincas en propiedad o a las que representa: {{this.fincas}}
                </div>
            </div>
            <div class="row linksPuntosConvocatoria">
                <div class="col-lg-6 col-md-6 col-sm-12 text-center">
                   <a class="help" href="javascript:void(0)" @click="onSubmitNombrarRepresentante">Nombrar un representante</a>
                </div>
                <div class="col-lg-6 col-md-6 col-sm-12 text-center">
                     <a class="help" href="javascript:void(0)" @click="recargarDatos"><i class="fa-solid fa-rotate-right"></i>  Recargar datos</a>
                </div>
            </div>
            <div v-for="(item, index) in this.puntosConvocatoria" :key="index" class="puntoConvocatoria">              
                   <punto-convocatoria-votacion :numeroPunto="index + 1" :item="item" :codigosVotacion="codigosVotacion" :tipoRespuestas="tipoRespuestas" :isPregunta="false" :email="email" :convocatoriaId="convocatoriaId"/>
                   <div v-for="(pregunta, iPregunta) in item.preguntas" :key="iPregunta" class="puntoConvocatoria pregunta">
                      <punto-convocatoria-votacion :item="pregunta" :codigosVotacion="codigosVotacion" :tipoRespuestas="tipoRespuestas" :isPregunta="true" :email="email" :convocatoriaId="convocatoriaId"/>
                   </div>
            </div>
        </div>       
    </section>
</template>

<script>
import apiService from "@/api/Api.service";
import Loading from "vue-loading-overlay";
import { Modal } from 'usemodal-vue3';
import PuntoConvocatoriaVotacion from './PuntoConvocatoriaVotacion';

export default {
  name: "PuntosConvocatoriaInfo",
   components: {
    Loading,    
    Modal,
    PuntoConvocatoriaVotacion
  },
  data() {
    return {
      loading:false,
      puntosConvocatoria:[],
      isVisibleModal:false,
      errorMessage: "",
      successMessage: "",
      representanteEmail: "",
      representanteName: "",
      representanteNif: "",
      representanteId: null,
      propiedades:"",
      tipoRespuestas:null,
      fincas: "",
      picked:null,
      codigosVotacion:null,
      codigos:""
    };
  },
  props: {  
      setPropietarioRepresentado:Function,
      convocatoriaId:String,
      code:String,
      email:String,
  },
  mounted(){
    this.recargarDatos();
  },
  methods:{
      emit() {
        console.log("Emit");
        this.$emit("setPropietarioRepresentado", this.representanteId);
      },
      recargarDatos(){
          this.fincas = "";
          this.codigos = "";
          this.puntosConvocatoria=[];
          this.loading = true;
          let data = {
              Email: this.email,
              Code: this.code,
              ConvocatoriaId:this.convocatoriaId
          };
          apiService.registerInConvocatoria(data).then((responseRegister)=>{
            console.log("responseRegister",responseRegister);
          });
          apiService.getPuntosConvocatoriaWithVotaciones(this.convocatoriaId, this.email).then((responseConvocatoria)=>{
              this.puntosConvocatoria = responseConvocatoria.data;
              console.log("responseConvocatoria.data", this.puntosConvocatoria);
          });
          apiService.getCodesUserLogin(data).then((responseCodes)=>{
              this.codigosVotacion = responseCodes.data;
              responseCodes.data.forEach(element => {
                if(element.fincas != 'undefined' && element.fincas != ""){
                    this.fincas = this.fincas == "" ? element.fincas : this.fincas + "," + element.fincas; 
                    this.codigos = this.codigos == "" ? element.codigo: this.cogidos + "," + element.codigo;
                }
              });     
          });
          apiService.getEnums().then((responseEnums)=>{
              if (responseEnums.status == 200) {
                  this.tipoRespuestas = responseEnums.data.tipoRespuesta.filter(t=> t.displayName != "-");
              }
          }); 
          this.loading = false;
      },
      onSubmitNombrarRepresentante(){
          this.isVisibleModal = true;
      },
       validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      },
      validateForm() {
      this.errorMessage = "";   

      if (this.representanteName.trim().length < 3) {
        this.errorMessage = "El nombre debe tener más de 3 caracteres.";
        return false;
      }
      if (!this.validateEmail(this.representanteEmail.trim())) {
        this.errorMessage = "El email no es válido.";
        return false;
      }
      return true;
    },
      onSubmitRepresentante() {
      if (!this.validateForm()) return;
      this.successMessage = "";
      let data = {
        ConvocatoriaId: this.$route.params.convocatoriaId,
        CodigoVotante: this.code.trim(),
        NombreCompleto: this.representanteName.trim(),
        Email: this.representanteEmail.trim(),
        Nif: this.nif ? this.representanteNif.trim() : "",
      };

      this.loading = true;
      apiService.createRepresentante(data).then((response) => {
        if (
          response.status == 200 ||
          response.status == 201 ||
          response.status == 204
        ) {
          this.representanteId = response.data.id;     
          this.emit(response.data.id);
          this.successMessage = "El registro de representación se ha enviado satisfactoriamente! Ya puede cerrar la página";
          this.loading = false;
          this.isVisibleModal = false;           
        } else {
          this.errorMessage =
            "Ha ocurrido un error al guardar representante. " +
            String(response);
        }
        this.loading = false;
      });
    }   
  }
}
</script>