<template>
  <section class="section main-section parallax-scene-js top-bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 col-12">
          <div class="main-decorated-box text-center text-xl-left">
            <div class="wrapper-title">
              <h1 class="text-white text-xl-left wow slideInRight"
                data-aos-delay="300"
                data-aos="fade-up"
              >
                <span
                  class="align-top offset-top-30 d-inline-block font-weight-light prefix-text"
                >
                  Las</span
                >
                <span class="big font-weight-bold d-inline-flex offset-right-170"
                  >mejores</span
                >
                <span class="biggest d-block d-xl-flex font-weight-bold"
                  >soluciones.</span
                >
              </h1>
              
              <div data-aos="slide-up"
                class="decorated-subtitle text-italic text-white wow slideInLeft"
              >
                Para administradores de fincas colegiados
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 text-center offset-top-75" data-wow-delay=".2s">
          <a
            class="button-way-point d-inline-block text-center d-inline-flex flex-column justify-content-center"
            v-on:click.stop="scrollToAbout"
          >
            <span class="fa-chevron-down"></span
          ></a>
        </div>
      </div>
    </div>
    <div class="decorate-layer">
      <div class="layer-1">
        <div class="layer">
          <img
            :src="require('../assets/images/parallax-item-1-563x532.png')"
            alt=""
            width="563"
            height="266"
          />
        </div>
      </div>
      <div class="layer-2">
        <div class="layer">
          <img
            :src="require('../assets/images/parallax-item-2-276x343.png')"
            alt=""
            width="276"
            height="171"
          />
        </div>
      </div>
      <div class="layer-3">
        <div class="layer">
          <img
            :src="require('../assets/images/parallax-item-3-153x144.png')"
            alt=""
            width="153"
            height="72"
          />
        </div>
      </div>
      <div class="layer-4">
        <div class="layer">
          <img
            :src="require('../assets/images/parallax-item-4-69x74.png')"
            alt=""
            width="69"
            height="37"
          />
        </div>
      </div>
      <div class="layer-5">
        <div class="layer">
          <img
            :src="require('../assets/images/parallax-item-5-72x75.png')"
            alt=""
            width="72"
            height="37"
          />
        </div>
      </div>
      <div class="layer-6">
        <div class="layer">
          <img
            :src="require('../assets/images/parallax-item-6-45x54.png')"
            alt=""
            width="45"
            height="27"
          />
        </div>
      </div>
    </div>
  </section>
  <section class="section section-sm position-relative" id="about">
        <div class="container">
          <div class="row row-30">
            <div class="col-lg-6">
              <div class="block-decorate-img wow fadeInLeft" 
                data-aos-delay="200"
                data-aos="fade-down">
                <img :src="require('../assets/images/home-1-570x703.jpg')" 
                  alt="" width="570" height="351"/>
              </div>
            </div>
            <div class="col-lg-6 col-12">
              <div class="block-sm offset-top-45">
                <div class="section-name wow" 
                data-aos-delay="200"
                data-aos="fade-down">¿Qué es</div>
                <h3 class="wow fadeInUp text-capitalize devider-bottom" 
                  data-aos-delay="200"
                  data-aos="fade-up">
                  Tu Despacho<span class="text-primary"> Digital</span>
                </h3>
                <p class="offset-xl-40 default-letter-spacing font-weight-bold text-gray-dark wow fadeInUp"     
                  data-aos-delay="400"
                  data-aos="fade-up">
                Una solución integral de administración y gestión de las juntas de propietarios de sus comunidades.
                </p>
              <p class="wow fadeInUp"  data-aos-delay="400"
                        data-aos="fade-up">
                <ul class="lista-custom1">
                  <li>Planifique las juntas en función de su calendario.</li>
                  <li>Envíe la convocatoria a todos los propietarios de forma automatizada.</li>
                  <li>Controle la asistencia, la representación delegada y el derecho a voto desde un único lugar.</li>
                  <li>Realice juntas telemáticas con la mejor calidad y sin necesidad de un departamento informático.</li>
                  <li>Controle la votación de los participantes y obtenga los resultados de forma automática. La solución automatiza el cálculo en función del tipo de resultados que necesite  (mayoría simple, cualificada, porcentaje de asistencia…).</li>
                  </ul>
              </p>
                <a style="display:none" class="button-width-190 button-primary button-circle button-lg button offset-top-30" href="#">Ver más</a>
              </div>
            </div>
          </div>
        </div>
        <div class="decor-text decor-text-1">DIGITAL</div>
      </section>
      <!--Features-->
      <section class="section custom-section position-relative section-md">
        <div class="container">
          <div class="row">
            <div class="col-xl-7 col-lg-7 col-12">
              <div class="section-name wow fadeInRight">Organice</div>
              <h3 class="text-capitalize devider-left wow fadeInLeft" 
                data-aos-delay="200"
                        data-aos="fade-up"
                        >Juntas Virtuales 
                        <span class="text-primary"> con total confianza</span>
                  </h3>
              <p>Nuestra solución integra Microsoft Teams para proporcionar una experiencia de calidad en la realización de juntas telemáticas de propietarios:</p>
              <div class="row row-15">
                <div class="col-xl-6 wow fadeInUp" 
                data-aos-delay="200"
                        data-aos="fade-up">
                  <div class="box-default">
                    <div class="box-default-title">Cualquier dispositivo</div>
                    <p class="box-default-description">Los propietarios podrán conectarse desde un PC, una Tablet o un móvil. Incluso pueden acceder en modo solo audio desde un teléfono fijo.</p><span class="box-default-icon novi-icon icon-lg mercury-icon-mobile"></span>
                  </div>
                </div>
                <div class="col-xl-6 wow fadeInUp" 
                  data-aos-delay="300"
                        data-aos="fade-up">
                  <div class="box-default">
                    <div class="box-default-title">Grabación de la junta</div>
                    <p class="box-default-description">Mantenga un control de todo lo que se ha dicho en la junta y acceda a esa información en el momento que la necesite.</p><span class="box-default-icon novi-icon icon-lg mercury-icon-chat"></span>
                  </div>
                </div>
                <div class="col-xl-6 wow fadeInUp" 
                  data-aos-delay="400"
                        data-aos="fade-up">
                  <div class="box-default">
                    <div class="box-default-title">Compartir documentos</div>
                    <p class="box-default-description">Facilite a los asistentes la documentación relevante para cada punto del día, ya sea antes de la junta o mientras se esta celebrando.</p><span class="box-default-icon novi-icon icon-lg mercury-icon-paper"></span>
                  </div>
                </div>
                <div class="col-xl-6 wow fadeInUp" 
                  data-aos-delay="500"
                        data-aos="fade-up">
                  <div class="box-default">
                    <div class="box-default-title">Votación telemática</div>
                    <p class="box-default-description">Recoja las votaciones de los propietarios con derecho a voto por el método que le resulte más adecuado: vista de mano alzada, formulario telemático, por chat o por voz.</p><span class="box-default-icon novi-icon icon-lg mercury-icon-note"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="image-left-side wow slideInRight" 
          data-aos-delay="500" data-aos="slide-right">
          <img :src="require('../assets/images/home-2-636x480.jpg')"
            alt="" width="636" height="240"/>
        </div>
        <div class="decor-text decor-text-2 wow fadeInUp">Microsoft</div>
      </section>
        <!--Pricing-->
      <section class="section section-md bg-xs-overlay pricing-section">
        <div class="container">
          <div class="row row-50 justify-content-center">
            <div class="col-12 text-center col-md-10 col-lg-8">
              <!--<div class="section-name wow fadeInRight" data-wow-delay=".2s">Pricing Options</div>-->
              <h3 class="wow fadeInLeft text-capitalize" 
                  data-aos-delay="200"
                        data-aos="flip-up">Súmate ya<span class="text-primary"> a la nueva normalidad</span></h3>
              <p style="color: #000;">Las nuevas tecnologías están impulsando un nuevo cambio de rumbo en la forma de trabajar del sector. <br>Deja que te ayuden a reducir la carga de trabajo y a facilitar el contacto con tus clientes.</p>
            </div>
          </div>
          <div class="row row-30 justify-content-center">
           
            <div class="col-xl-4 col-md-6 col-12 wow fadeInUp" 
                  data-aos-delay="400"
                        data-aos="fade-up">
              <div class="pricing-box bg-gray-primary">
                <div class="pricing-box-tittle">Tu despacho Digital</div>
                <p>Para administradores de fincas colegiados</p>
                <hr/>
                <!--<div class="pricing-box-inner"><span class="pricing-box-price">10,70</span><span class="pricing-box-symbol">€</span></div>-->
                <div style="border:1px solid;border-radius: 30px ">
                  <div>Licencia Microsoft 365 E1:<br> 6,70 €/mes por usuario</div>
                  <div>Módulo gestión de juntas:<br> 4,00 €/mes por usuario</div>
                  <div>Soporte técnico: <br>9,00 €/mes por usuario</div>
                  <div class="pricing-box-label bg-gray-primary-light">Total: <br>19,70 €/mes por usuario.<br> Contrato anual </div>
                </div>
                <p>Gestión de comunidades<br>Gestión de convocatorias<br>Planificación y control de juntas virtuales<br>Control de votaciones telemáticas<br>Acceso a Microsoft Teams<br>Grabación de juntas<br>Acceso a Office 365</p><a class="button button-190 button-circle btn-rounded-outline" href="/contacto">Me interesa</a>
              </div>
            </div>
          </div>
        </div>
      </section>
</template>
<script>
export default {
  name: "HomePage",
  data() {
    return {};
  },
  methods: {
    scrollToAbout: function () {
      const element = document.getElementById("about");
      window.scrollTo({
        top: element.offsetTop,
        behavior: "smooth",
      });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top-bg {
  background: url(../assets/images/bg-1-1700x803.jpg) center center / cover
    no-repeat;
  transform: translate3d(0px, 0px, 0px);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  position: relative;
}
.pricing-section {
  background: url(../assets/images/bg-image-3-1700x883.jpg) no-repeat;
  background-size: cover;
}
.wrapper-title {
  padding: 20px;
  background-color: rgba(211, 5, 44, 0.8);
  border-radius: 20px;
}
</style>
