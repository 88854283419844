<template>
  <section class="section main-section section-intro context-dark">
    <div class="intro-bg"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <h1 class="font-weight-bold wow fadeInLeft" data-aos="fade-right">
            Modelos de comunicación
          </h1>
        </div>
      </div>
    </div>
  </section>
  <section class="section section-lg bg-default">
    <div class="container">
      <div class="row row-30">
        <div class="cell-xs-10 col-md-6 col-lg-4">
          <article class="box-minimal">
            <div
              class="box-minimal-icon linearicons linearicons-users-plus"
            ></div>
            <h4 class="box-minimal-title">Convocatoria</h4>
            <div class="box-minimal-text">
              <p>Ejemplo de un modelo de una convocatoria tipo.</p>
              <a
                target="_blank"
                class="button-width-190 button-primary button-circle button-sm button offset-top-30"
                href="/docs/ejemplo_convocatoria_junta_ordinaria.pdf"
                >Ver documento</a
              >
            </div>
          </article>
        </div>
        <div class="cell-xs-10 col-md-6 col-lg-4">
          <article class="box-minimal">
            <div
              class="box-minimal-icon linearicons linearicons-file-preview"
            ></div>
            <h4 class="box-minimal-title">Acta</h4>
            <div class="box-minimal-text">
              <p>Ejemplo de un acta de junta de propietarios virtual.</p>
              <a
                target="_blank"
                class="button-width-190 button-primary button-circle button-sm button offset-top-30"
                href="/docs/Ejemplo-acta-junta-cafmadrid.pdf"
                >Ver documento</a
              >
            </div>
          </article>
        </div>
        <div class="cell-xs-10 col-md-6 col-lg-4">
          <article class="box-minimal">
            <div class="box-minimal-icon linearicons linearicons-warning"></div>
            <h4 class="box-minimal-title">Modelo de representación</h4>
            <div class="box-minimal-text">
              <p>Información necesaria para delegar en un representante.</p>
              <a
                target="_blank"
                class="button-width-190 button-primary button-circle button-sm button offset-top-30"
                href="/docs/modelo_de_representacion.pdf"
                >Ver documento</a
              >
            </div>
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ModelosCumplimentacionPage",
  data() {
    return {};
  },
  mounted() {},
  created() {},
  methods: {},
};
</script>

<style scoped>
a {
  text-decoration: underline;
}
a:hover {
  color: red;
}
</style>
