<template>
  <a
    class="section section-banner"
    href="https://cafmadrid.es/"
    target="_blank"
  >
    <img
      :src="require('../assets/images/foreground-01-1600x310.png')"
      :srcset="
        require('../assets/images/foreground-01-1600x310.png') +
        ' 1x, ' +
        require('../assets/images/foreground-01-3200x620.png') +
        ' 2x'
      "
      alt=""
      width="1600"
      height="310"
  /></a>

  <footer class="section footer-classic section-sm">
    <div class="container">
      <div class="row row-30">
        <div class="col-lg-3 wow" data-aos="fade-down">
          <!--Brand--><a class="brand" href="#">
            <img
              class="brand-logo-dark"
              :src="require('../assets/images/logo-default-200x34.png')"
              alt=""
              width="100"
              height="17" />
            <img
              class="brand-logo-light"
              :src="require('../assets/images/logo-inverse-200x34.png')"
              alt=""
              width="100"
              height="17"
          /></a>
        </div>
        <div class="col-lg-3 col-sm-8 wow fadeInUp" data-aos="fade-up">
          <P class="footer-classic-title">Información de contacto</P>
          <div class="d-block offset-top-0">
            García de Paredes, 70<span
              class="d-lg-block"
              style="margin-left: 5px"
              >28010, MADRID</span
            >
          </div>
          <a
            class="d-inline-block accent-link"
            href="mailto:tudespachodigital@zertia.es  "
            >tudespachodigital@zertia.es</a
          ><a
            class="d-inline-block"
            href="tel:+34910312298"
            style="margin-left: 5px"
          >
            91 031 22 98</a
          >
          <ul>
            <li>
              Lunes a viernes:<span
                class="d-inline-block offset-left-10 text-white"
                >9:00 a 18:30</span
              >
            </li>
          </ul>
        </div>
        <div
          class="col-lg-2 col-sm-4 wow fadeInUp"
          data-aos-delay="300"
          data-aos="fade-up"
        >
          <P class="footer-classic-title">Enlaces rápidos</P>
          <ul class="footer-classic-nav-list">
            <li><a href="#">Inicio</a></li>
            <li><a href="/ayuda-recursos">Ayuda y recursos</a></li>
            <li><a href="/contacto">Contacto</a></li>
          </ul>
        </div>
        <div
          class="col-lg-2 col-sm-4 wow fadeInUp"
          data-aos-delay="300"
          data-aos="fade-up"
        >
          <P class="footer-classic-title"></P>
          <ul class="footer-classic-nav-list">
            <li>
              <a href="/terminos-condiciones-proteccion-datos"
                >Protección de Datos</a
              >
            </li>
            <li><a href="/aviso-legal">Aviso Legal</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div
      class="container wow fadeInUp"
      data-aos-delay="400"
      data-aos="slide-up"
    >
      <div class="footer-classic-aside">
        <p class="rights">
          <span>&copy;&nbsp;</span><span class="copyright-year"></span>. All
          Rights Reserved.
        </p>
        <ul style="display: none" class="social-links">
          <li><a class="fa fa-linkedin" href="#"></a></li>
          <li><a class="fa fa fa-twitter" href="#"></a></li>
          <li><a class="fa fa-facebook" href="#"></a></li>
          <li><a class="fa fa-instagram" href="#"></a></li>
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.section-banner {
  background-image: url(../assets/images/background-01-1920x310.jpg);
  background-image: -webkit-image-set(
    url(../assets/images/background-01-1920x310.jpg) 1x,
    url(../assets/images/background-01-3840x620.jpg) 2x
  );
}
</style>