<template>
  <section class="main-section">
    <div class="error-template">
      <h1>Oops!</h1>
      <h2>No encontrado</h2>
      <div class="error-details">
        Lo sentimos, no pudimos encontrar la página!
      </div>
      <div class="error-actions">
        <a @click="$router.go(-1)" class="fas fa-arrow-circle-left">Volver</a>
        <router-link :to="{ name: 'Home' }" class="fa fa-home link"
          >Ir a página principal</router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFoundPage",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.error-template {
  text-align: center;
}
.link {
  margin-left: 30px;
}
.link:hover {
  color: #2c3e50;
}
.error-actions {
  margin-top: 15px;
  margin-bottom: 15px;
  font-size: 25px;
}
.error-actions a {
  cursor: pointer;
}
</style>
