//import Vue from 'vue'
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import jsonConfigFile from "../configuration.json";
import AOS from "aos";
import "bootstrap/dist/css/bootstrap.css";
import "aos/dist/aos.css";
import "vue-loading-overlay/dist/vue-loading.css";
import "./assets/css/bootstrap.css";
import "./assets/css/fonts.css";
import "./assets/css/trunk-style.css";
import "./assets/css/custom.css";
import Toaster from "@meforma/vue-toaster";

/*
import './assets/js/core.min.js';
import './assets/js/script.js';
*/

//var toastr = require("toastr");

function configAxios() {
  if (process.env.VUE_APP_API_HOST)
    axios.defaults.baseURL = process.env.VUE_APP_API_HOST;
  axios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      console.error("General error interceptors response", error.response);
      const customAPIMessage =
        error.response &&
        error.response.data &&
        error.response.data.StatusCode &&
        error.response.data.Message
          ? error.response.data
          : null;

      console.error("customAPIMessage", customAPIMessage);
      //solo mostramos si es badRequest
      return customAPIMessage && customAPIMessage.StatusCode == 400
        ? customAPIMessage.Message
        : String(error);
      /*if (Toaster) {
                Toaster.error(error);
            }*/
    }
  );
}
configAxios();

const app = createApp(App);

app.config.globalProperties.$generalConfig = jsonConfigFile;
/*
new Vue({
  render: (h) => h(App),
  mounted() {
    AOS.init()
  },
}).$mount('#app')
*/
app
  .use(router)
  .use(Toaster)
  .mount("#app");

// https://github.com/michalsnik/aos#animations
window.AOS = AOS;
AOS.init();
