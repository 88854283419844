<template>
  <section class="section main-section section-intro context-dark">
    <div class="intro-bg"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <h3 class="font-weight-bold wow" data-aos="slide-up">Aviso Legal</h3>
        </div>
      </div>
    </div>
  </section>
  <section class="section section-sm bg-default">
    <div class="container">
      <div class="row">
        <div class="main-page-wrapper">
          <p>
            El titular de esta página Web es El Colegio Profesional de
            Administradores de Fincas de Madrid, C/ García de Paredes, 70,
            Madrid, con C.I.F. G-28.840.387, e-mail secretaria@cafmadrid.es,
            teléfono 915 919 670,
            <em
              >Se puede acceder al contenido de la inscripción de este Colegio
              en el siguiente enlace (área de descargas)&nbsp;<a
                href="https://www.comunidad.madrid/servicios/asociaciones-fundaciones-colegios-profesionales/colegios-profesionales"
                >https://www.comunidad.madrid/servicios/asociaciones-fundaciones-colegios-profesionales/colegios-profesionales</a
              >.</em
            >
          </p>
          <p>
            La utilización de esta página Web atribuye la condición de Usuario a
            quien lo haga e implica la aceptación de todas las condiciones
            incluidas en este Aviso Legal. El Usuario deberá leer estas
            condiciones cada vez que acceda a esta Web, ya que pueden ser
            modificadas en lo sucesivo. Algunos aspectos de esta página Web, por
            su especialidad, pueden estar sujetos a condiciones o reglas
            particulares que pueden sustituir, completar o modificar este Aviso
            Legal, por lo que también deben ser aceptadas por el Usuario que los
            utilice o acceda a ellos.
          </p>
          <p>
            Todos los contenidos de esta Web (textos, fotografías, gráficos,
            imágenes, tecnología, software, links, contenidos audiovisuales o
            sonoros, diseño gráfico, código fuente, etc.), así como las marcas y
            demás signos distintivos son propiedad del Colegio Profesional de
            Administradores de Fincas de Madrid o de terceros, no adquiriendo el
            Usuario ningún derecho sobre ellos por el mero uso de esta Web.
          </p>
          <p>
            Queda prohibido el uso de esta Web con la finalidad de lesionar
            bienes, derechos o intereses del Colegio Profesional de
            Administradores de Fincas de Madrid o de terceros. Queda igualmente
            prohibido realizar cualquier otro uso de esta Web que altere, dañe o
            inutilice las redes, servidores, equipos, productos y programas
            informáticos del Colegio Profesional de Administradores de Fincas de
            Madrid o de terceros.
          </p>
          <p>
            El Usuario, deberá abstenerse de: a) reproducir, copiar, distribuir,
            poner a disposición de terceros, comunicar públicamente, transformar
            o modificar los contenidos de esta Web, salvo en los casos
            contemplados en la ley o expresamente autorizados por Colegio
            Profesional de Administradores de Fincas de Madrid o por el titular
            de dichos derechos; b) reproducir o copiar para uso privado el
            software o las bases de datos existentes en esta Web, así como
            comunicarlos públicamente o ponerlos a disposición de terceros
            cuando ello conlleve su reproducción; c) extraer o reutilizar todo o
            parte sustancial de los contenidos integrantes de esta Web.
          </p>
          <p>
            El Usuario es responsable de que la información que proporcione al
            Colegio Profesional de Administradores de Fincas de Madrid a través
            de esta Web sea cierta. A estos efectos, el Usuario responde de la
            veracidad de todos los datos que comunique y mantendrá
            convenientemente actualizada la información facilitada, de tal forma
            que responda a su situación real. El Usuario será responsable de las
            informaciones falsas o inexactas que proporcione y de los perjuicios
            que ello cause al Colegio Profesional de Administradores de Fincas
            de Madrid o a terceros.
          </p>
          <p>
            El Usuario que quiera introducir enlaces a esta Web desde sus
            propias páginas Web estará obligado a cumplir las condiciones
            siguientes: a) el enlace únicamente vinculará con la home page, no
            pudiendo reproducirla de ninguna forma; b) no podrán establecerse
            frames o marcos de cualquier tipo que rodeen la Web o permitan
            visualizarla a través de direcciones de Internet distintas o
            conjuntamente con contenidos ajenos a esta Web, de forma que
            produzca, o pueda producir, error o confusión en los usuarios sobre
            la procedencia del servicio o su contenido, implique un acto de
            comparación o imitación desleal, sirva para aprovechar la
            reputación, marca y prestigio del Colegio Profesional de
            Administradores de Fincas de Madrid o se haga de cualquier otro modo
            que esté prohibido por la Ley; c) desde la página que realice el
            enlace no podrá efectuarse ningún tipo de manifestación falsa o
            inexacta sobre el Colegio Profesional de Administradores de Fincas
            de Madrid, sobre la calidad de sus servicios o sobre sus colegiados,
            empleados o miembros; d) El remitente no podrá utilizar la marca o
            cualquier otro signo distintivo del Colegio Profesional de
            Administradores de Fincas de Madrid dentro de su página Web, salvo
            en los casos autorizados por la ley o permitidos expresamente por el
            Colegio Profesional de Administradores de Fincas de Madrid; e) la
            página que establezca el enlace deberá observar la legalidad vigente
            y no podrá disponer de o enlazar con contenidos ilícitos, nocivos,
            contrarios a la moral y a las buenas costumbres, que produzcan o
            puedan producir la falsa idea de que el Colegio Profesional de
            Administradores de Fincas de Madrid respalda o apoya, las ideas,
            manifestaciones o actuaciones del remitente o que resulten
            inadecuados en relación con la actividad desarrollada por el Colegio
            Profesional de Administradores de Fincas de Madrid, teniendo en
            cuenta los contenidos y la temática general de la página Web donde
            se establezca el enlace.
          </p>
          <p>
            El Colegio Profesional de Administradores de Fincas de Madrid no es
            responsable de controlar que en esta Web no existan programas
            maliciosos o cualquier otro elemento informático dañino. Corresponde
            al Usuario, en todo caso, disponer de herramientas adecuadas para la
            detección y desinfección de estos elementos. De acuerdo con ello, el
            Colegio Profesional de Administradores de Fincas de Madrid no se
            responsabiliza de los daños producidos a equipos informáticos
            durante el acceso a la presente Web. Igualmente, el Colegio
            Profesional de Administradores de Fincas de Madrid no será
            responsable de los daños producidos a los Usuarios cuando dichos
            daños tengan su origen en fallos o desconexiones en las redes de
            telecomunicaciones que interrumpan el servicio de esta Web.
          </p>
          <p>
            Esta Web incluye dispositivos técnicos de enlace que permiten al
            Usuario acceder a otras páginas de Internet (enlaces). En estos
            casos, el Colegio Profesional de Administradores de Fincas de Madrid
            sólo será responsable de los contenidos y servicios suministrados a
            través de los enlaces en cuanto conozca su ilicitud y no los haya
            desactivado diligentemente. Si un Usuario considerase que existe un
            enlace con contenidos ilícitos o inadecuados, podrá indicárselo al
            Colegio, sin que ello suponga que esta quede obligada por ello a
            retirar el correspondiente enlace. El Colegio Profesional de
            Administradores de Fincas de Madrid no conoce los contenidos y
            servicios de los enlaces y por tanto no se hace responsable por los
            daños derivados de su falta de calidad, desactualización,
            indisponibilidad, error, inutilidad o ilegalidad y no responde de
            las manifestaciones realizadas o de los contenidos o servicios
            proporcionados a través de ellos. Si cualquier usuario tiene
            conocimiento de que los enlaces remiten a páginas cuyos contenidos o
            servicios son ilícitos, nocivos, denigrantes, violentos o inmorales
            podrá ponerse en contacto con el Colegio Profesional de
            Administradores de Fincas de Madrid indicándolo.
          </p>
          <p>
            El presente Aviso Legal se rige íntegramente por la legislación
            española. El Usuario se compromete a hacer un uso correcto de esta
            Web de conformidad con la Ley, con el presente Aviso Legal, así como
            con las demás condiciones, reglamentos e instrucciones que, en su
            caso, pudieran ser de aplicación El Usuario responderá frente al
            Colegio Profesional de Administradores de Fincas de Madrid y frente
            a terceros, de cualesquiera daños o perjuicios que pudieran causarse
            por incumplimiento de estas obligaciones.»
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PoliticaProteccionDatosPage",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h4 {
  margin-top: 15px;
}
@media (min-width: 992px) {
  .section-intro {
    padding: 120px 0 20px 0;
  }
}
</style>
