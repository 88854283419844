<template>
  <section class="section section-intro context-dark">
    <div class="intro-bg"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 text-center">
          <h1 class="font-weight-bold wow fadeInLeft" data-aos="fade-left">
            Ayuda y recursos
          </h1>
          <p class="intro-description wow fadeInRight" data-aos="fade-right">
            Acceso a plantillas y recursos de utilidad para el uso de la
            plataforma
          </p>
        </div>
      </div>
    </div>
  </section>
  <section class="section section-md">
    <div class="container">
      <div class="row row-50 justify-content-center">
        <div class="col-md col-12 text-center">
          <!--<div class="section-name wow fadeInRight" data-wow-delay=".2s">People Behind Our Success</div>-->
          <h3
            class="wow fadeInLeft text-capitalize"
            data-aos="fade-right"
            data-aos-delay="300"
          >
            ¿Qué tipo de ayuda<span class="text-primary"> necesitas?</span>
          </h3>
          <p class="block-675">
            Primero selecciona tu perfil de usuario para acceder a los recursos
            específicos disponibles.
          </p>
        </div>
      </div>
      <div class="row row-50 justify-content-center">
        <div
          class="col-xl-4 col-sm-6 col-10 wow"
          data-aos="slide-up"
          data-aos-delay="300"
        >
          <div class="team-classic-wrap">
            <div class="team-classic-img">
              <a href="#propietarios">
                <img
                  :src="require('../assets/images/team-classic-1-370x397.jpg')"
                  alt=""
                  width="370"
                  height="198"
              /></a>
            </div>
            <div class="block-320 text-center">
              <h4 class="font-weight-bold">Propietario</h4>
              <hr class="offset-top-40" />
            </div>
          </div>
        </div>
        <div
          class="col-xl-4 col-sm-6 col-10 wow fadeInRight"
          data-aos="fade-right"
          data-aos-delay="300"
        >
          <div class="team-classic-wrap">
            <div class="team-classic-img">
              <a href="#administradores">
                <img
                  :src="require('../assets/images/team-classic-3-370x397.jpg')"
                  alt=""
                  width="370"
                  height="198"
              /></a>
            </div>
            <div class="block-320 text-center">
              <h4 class="font-weight-bold">Administrador de fincas</h4>
              <hr class="offset-top-40" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- propietarios section-->
  <section class="section section-md" id="propietarios">
    <div class="container">
      <div class="row row-40 justify-content-center">
        <div class="col-lg-6 col-12">
          <div class="offset-top-45 offset-lg-right-45">
            <!--<div class="section-name wow fadeInRight" data-wow-delay=".2s">Propietarios</div>-->
            <h3
              class="wow fadeInLeft text-capitalize"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              Recursos para<span class="text-primary"> Propietarios</span>
            </h3>
            <p
              class="font-weight-bold text-gray-dark wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              En esta sección podrán encontrar documentos de ayuda para los
              propietarios de fincas.
            </p>
            <p class="wow fadeInUp" data-aos="fade-up" data-aos-delay="400">
              <a
                class="help"
                href="https://support.microsoft.com/es-es/office/unirse-a-una-reuni%C3%B3n-en-teams-1613bb53-f3fa-431e-85a9-d6a91e3468c9?ui=en-us&rs=en-us&ad=us"
                target="_blank"
              >
                Instrucciones para el acceso a juntas virtuales</a
              >
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-sm-10 col-12">
          <div
            class="block-decorate-img wow fadeInLeft"
            data-aos="fade-right"
            data-aos-delay="200"
          >
            <img
              :src="require('../assets/images/about-1-570x350.jpg')"
              alt=""
              width="570"
              height="351"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- administradores section-->
  <section class="section section-md" id="administradores">
    <div class="container">
      <div class="row row-40 justify-content-center">
        <div class="col-lg-6 col-12">
          <div class="offset-top-45 offset-lg-right-45">
            <h3
              class="wow fadeInLeft text-capitalize"
              data-aos="fade-right"
              data-aos-delay="300"
            >
              Recursos para<span class="text-primary"> Administradores</span>
            </h3>
            <p
              class="font-weight-bold text-gray-dark wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              En esta sección podrán encontrar documentos de ayuda para los
              administradores.
            </p>
            <p class="wow fadeInUp" data-aos="fade-up" data-aos-delay="400">
              <router-link :to="{ name: 'ModelosCumplimentacion' }" class="help"
                >Modelos para la cumplimentación de los datos</router-link
              >
            </p>
            <p class="wow fadeInUp" data-aos="fade-up" data-aos-delay="400">
              <router-link :to="{ name: 'FAQPropietariosPage' }" class="help"
                >Preguntas frecuentes</router-link
              >
            </p>
            <p
              style="display: none"
              class="wow fadeInUp"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <router-link :to="{ name: 'Guias' }" class="help"
                >Tutoriales y guías</router-link
              >
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-sm-10 col-12">
          <div
            class="block-decorate-img wow fadeInLeft"
            data-aos="fade-right"
            data-aos-delay="300"
          >
            <img
              :src="require('../assets/images/about-1-570x350B.jpg')"
              alt=""
              width="570"
              height="351"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AyudaRecursosPage",
  data() {
    return {};
  },
  methods: {},
};
</script>
<style>
</style>