<template>
  <header class="section page-header" id="myHeader">
    <!--RD Navbar-->
    <div class="rd-navbar-wrap">
      <nav
        id="navMenu"
        :class="
          'vue-navbar rd-navbar-classic rd-navbar-original  ' +
          (miniScreen ? ' rd-navbar-fixed' : 'rd-navbar-static')
        "
        data-layout="rd-navbar-fixed"
        data-sm-layout="rd-navbar-fixed"
        data-md-layout="rd-navbar-fixed"
        data-md-device-layout="rd-navbar-fixed"
        data-lg-layout="rd-navbar-static"
        data-lg-device-layout="rd-navbar-static"
        data-xl-layout="rd-navbar-static"
        data-xl-device-layout="rd-navbar-static"
        data-lg-stick-up-offset="46px"
        data-xl-stick-up-offset="46px"
        data-xxl-stick-up-offset="46px"
        data-lg-stick-up="true"
        data-xl-stick-up="true"
        data-xxl-stick-up="true"
      >
        <div
          data-rd-navbar-toggle=".rd-navbar-collapse"
          v-on:click="isExpanded = !isExpanded"
          :class="
            'rd-navbar-collapse-toggle rd-navbar-fixed-element-1 ' +
            (miniScreen ? 'toggle-original' : '') +
            ' ' +
            (!isExpanded ? ' active' : '')
          "
        >
          <span></span>
        </div>

        <div class="rd-navbar-main-outer">
          <div class="rd-navbar-main">
            <!--RD Navbar Panel-->
            <div class="rd-navbar-panel">
              <!--RD Navbar Toggle-->
              <button
                id="navToggleButton"
                @click="isExpanded = !isExpanded"
                :class="
                  'rd-navbar-toggle ' +
                  (miniScreen ? 'toggle-original' : '') +
                  ' ' +
                  (isExpanded ? ' active' : '')
                "
                data-rd-navbar-toggle=".rd-navbar-nav-wrap"
              >
                <span></span>
              </button>
              <!--RD Navbar Brand-->
              <div class="rd-navbar-brand">
                <!--Brand--><a class="brand" href="/">
                  <img
                    class="brand-logo-dark"
                    :src="require('../assets/images/logo-default-200x34.png')"
                    alt=""
                    width="100"
                    height="17" />
                  <img
                    class="brand-logo-light"
                    :src="require('../assets/images/logo-inverse-200x34.png')"
                    alt=""
                    width="100"
                    height="17"
                /></a>
              </div>
            </div>
            <div class="rd-navbar-main-element">
              <div
                id="navMenuWrapperUl"
                :class="
                  'rd-navbar-nav-wrap ' +
                  (miniScreen ? 'toggle-original-elements' : '') +
                  ' ' +
                  (isExpanded ? ' active' : '')
                "
              >
                <ul class="rd-navbar-nav">
                  <li class="rd-nav-item">
                    <router-link to="/" class="rd-nav-link">Inicio</router-link>
                  </li>
                  <li class="rd-nav-item">
                    <router-link to="/ayuda-recursos" class="rd-nav-link"
                      >Ayuda y recursos</router-link
                    >
                  </li>
                  <!--<li class="rd-nav-item"><a class="rd-nav-link" href="typography.html">Typography</a>
                      </li>-->
                  <li class="rd-nav-item">
                    <router-link to="/contacto" class="rd-nav-link"
                      >Contacto</router-link
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
</template>
<script>
export default {
  name: "NavMenu",
  data() {
    return {
      isExpanded: false,
      miniScreen: false,
      corporativeSecondaryColor: this.$generalConfig.theme
        .corporativeSecondaryColor,
      corporativeColor: this.$generalConfig.theme.corporativeColor,
      minScreenMedia: this.$generalConfig.theme.minScreenMedia,
      maxScreenMedia: this.$generalConfig.theme.maxScreenMedia,
      maxMiniScreenMedia: this.$generalConfig.theme.maxMiniScreenMedia,
    };
  },
  mounted() {
    this.manageWindowResolutionSideEffects();
    window.addEventListener("resize", this.manageWindowResolutionSideEffects);
    window.addEventListener("click", this.handleClick);
  },
  unmounted() {
    window.removeEventListener(
      "resize",
      this.manageWindowResolutionSideEffects
    );
    window.removeEventListener("click", this.handleClick);
  },
  methods: {
    handleClick(event) {
      if (this.isExpanded) {
        const target = document.getElementById("myHeader");
        const withinBoundaries = event.composedPath().includes(target);

        if (withinBoundaries) {
          //console.log("Click happened inside element myHeader");
        } else {
          //console.log("Click happened **OUTSIDE** element myHeader");
          this.isExpanded = false;
        }
      }
    },
    manageWindowResolutionSideEffects() {
      if (window.innerWidth < 975) {
        this.miniScreen = true;
        //console.log("mini Screen ");
      } else {
        this.miniScreen = false;
        //console.log("big Screen");
      }
      //console.log("height: ", window.innerHeight, "px");
      //console.log("width: ", window.innerWidth, "px");
    },
    collapse() {
      this.isExpanded = false;
    },
  },
};
</script>

<style>
a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}
nav a.router-link-active,
nav a.router-link-exact-active {
  border-bottom: 1px solid white;
}
.vue-navbar {
  background: #ccc;
}
</style>