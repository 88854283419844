<template>
  <section class="section section-intro context-dark">
    <div class="intro-bg"></div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-12 text-center">
          <h1 class="font-weight-bold wow fadeInLeft" data-aos="fade-right">
            Votación telemática
          </h1>
        </div>
      </div>
    </div>
  </section>
  <section class="section section-md juntaVirtual">
    <loading v-model:active="loading" :is-full-page="true"></loading>
    <div class="container">
      <!--RD Mailform-->
      <div class="row justify-content-center">
        <div class="col-12">
          <div
            v-if="errorMessage.length > 0"
            class="alert alert-danger"
            role="alert"
          >
            {{ errorMessage }}
          </div>
          <div
            v-if="successMessage.length > 0"
            class="alert alert-success"
            role="alert"
          >
            {{ successMessage }}
          </div>
          <div v-if="isLogin == false">
              <div class="row text-center justify-content-md-center" v-if="isPropietario == false">
                  <div class="col-lg-12 col-md-12 col-sm-12" v-if="successMessage.length <= 0">
                    <h3> Bienvenido. </h3>
                    <h4> Para validar su acceso debe introducir el mail en el que recibio la invitación a la reunión </h4>
                  </div>                                        
                  <div class="col-lg-6 col-md-6 col-sm-12 mt-4 col-center form-wrap">
                      <input
                        placeholder="Email de acceso"
                        title="Email"
                        class="form-input input2"
                        id="contact-email"
                        type="email"
                        name="email"
                        v-model="email"    
                        :disabled="!showEmail"   
                        @change="changeEmail"        
                      />
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-12 form-button group-sm text-center">
                      <button
                        title="Enviar"
                        class="button button-primary"
                        type="button"
                        :disabled="
                          loading ||
                          successMessage ||
                          errorMessage || !showEmail
                        "
                        @click="onSubmit"                        
                      >
                        Enviar
                      </button>
                    </div>
               </div>
              <div class="row text-center justify-content-md-center" v-if="isPropietario == true">
                 <div class="col-lg-12 col-md-12 col-sm-12">
                      <h3>Confirma tu e-mail</h3>
                      <h4 v-if="errorMessage.length <= 0 ">
                      Hemos enviado un código de verificación a {{email}} para asegurarnos de que eres realmente tú. (mira en tu carpeta de correo no deseado)
                      </h4>
                      <h4 v-if="errorMessage.length > 0 ">
                          ¿Quiere volver a intentarlo?
                      </h4> 
                  </div>       
                  <div class="col-lg-6 col-md-6 col-sm-12 mt-4 col-center form-wrap">
                      <input
                        placeholder="Código de verificación"
                        title="Código de verificación"
                        class="form-input input2"
                        type="number"
                        hide-spin-buttons = "false"
                        id="codigoVerificacion"
                        name="codigoVerificacion"
                        v-model="codigoVerificacion" 
                        maxlength="4"
                        @change="changeCodigoValidacion"                                    
                      />
                  
                     <a v-if="errorMessage.length > 0" class="help" href="javascript:void(0)" @click="onSubmitAgain">
                          Mandar otro código.
                      </a>
                      <a v-if="errorMessage.length <= 0 " class="help" href="javascript:void(0)" @click="onSubmitAgain">No he recibido el email. Volver a enviar.</a>
                    </div>
                    <div class="col-lg-8 col-md-8 col-sm-12 mt-4 form-button group-sm text-center">
                      <button
                        title="Verificar"
                        class="button button-primary"
                        type="button"
                        @click="onSubmitVerificacion"
                      >
                        Verificar Código*
                      </button>
                      <span>*Al pulsar esta aceptando realizar la votación de forma telemática y la utilización de cookies para guardar su información de acceso.</span>
                    </div>
              </div>
          </div>
          <div v-if="isLogin == true">
             <button
                        title="Salir de la votación"
                        class="button button-primary"
                        type="button"
                        @click="onSubmitLogOut"
                      >
                        Salir de la votación
                      </button>
              <junta-virtual-info :convocatoriaId="convocatoriaId" :code="code" :email="email"/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import Vue from "vue";
import router from "../router/index";
import apiService from "@/api/Api.service";
import Loading from "vue-loading-overlay";
import JuntaVirtualInfo from "../components/juntaVirtual/JuntaVirtualInfo";

export default {
  name: "JuntaVirtualPage",
  components: {
    Loading,
    JuntaVirtualInfo
  },
  data() {
    return {
      convocatoriaId: this.$route.params.convocatoriaId,
      code: this.$route.params.CodigoVotante,
      email: "",
      loading: false,
      errorMessage: "",
      successMessage: "",
      infoConvocatoria: "",
      showEmail: false,
      isPropietario: false,
      isLogin:false,
      codigoVerificacion:"",
      controlAsistenciaId:null,
    };
  },
  mounted() {
    console.log("mounted", this.convocatoriaId);
    if (!this.isUUID(this.convocatoriaId)) {
      router.push("/");
    } 
    else
    {
      apiService.isLogin(this.$route.params.convocatoriaId,
      this.$route.params.CodigoVotante,).then((responseLogin)=>{
        console.log("responseLogin", responseLogin.status, responseLogin.data);
        this.isLogin = false;
        if(responseLogin.status != 404 && responseLogin.data != ""){
              this.email = responseLogin.data;
              this.isLogin = true;
              this.convocatoriaId = this.$route.params.convocatoriaId;
              this.code = this.$route.params.CodigoVotante;
        }
      });

      apiService.getMeetingDate(this.convocatoriaId).then((response) => {
          try {
            if (
              response.status == 200 &&             
              response.data !== null
            ) {
              const result = response.data;
              const objDate = new Date(result);
              
              const now = Date.now();
              const objDatePrevia = objDate;
              objDatePrevia.setMinutes(objDatePrevia.getMinutes() - 30);
              if(now > objDatePrevia){
                  this.showEmail = true;
              }
              else{
                this.showEmail = false;
                this.errorMessage = "No puede acceder hasta 30 minutos antes de la hora convocada." 
              }
            } else {
              this.errorMessage =
                "Error verificando convocatoria " +
                this.convocatoriaId +
                ". Detalles: " +
                String(response) +
                "";
              console.error("error quering meeting then result", response);           
            }
          } catch (e) {
            console.error("getMeeting then result", e);
          }
        });
    }
  },
  methods: {
    isUUID(uuid) {
      let s = "" + uuid;
      s = s.match(
        "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$"
      );
      if (s === null) {
        return false;
      }
      return true;
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validateForm() {
      this.errorMessage = "";
      if (this.convocatoriaId.length < 3) {
        this.errorMessage = "Falta información de la convocatoria.";
        return false;
      }
      if (!this.validateEmail(this.email.trim())) {
        this.errorMessage = "El email no es válido.";
        return false;
      }
      return true;
    },
    onSubmit() {
      if (!this.validateForm()) return;
      this.successMessage = "";

      let data = {
        ConvocatoriaId: this.convocatoriaId,
        Email: this.email.trim(),
        Code: this.code
      };
      console.log("data", data);
      this.loading = true;  
      apiService.isPropietarioOrRepresentante(data).then((response) => {
        try {
          if (response.status == 200) {
            const result = response.data;
            console.log("isPropietarioOrRepresentante: ", result);
            if(result == true){               
                this.isPropietario = true;   
                 console.log("response ok", this.isPropietario);    
                 console.log("enviar disable ", this.loading, this.errorMessage, this.successMessage);    
            }
            else{
                this.errorMessage = "El email no es válido para acceder a la junta virtual.";
            }
          }
          else{
              this.errorMessage = "El email no es válido para acceder a la junta virtual.";
          }          
        }
        catch(error){
          console.log("isPropietarioOrRepresentante error", error);
        }
        this.loading = false;  
      });
    },
    onSubmitAgain(){
      this.onSubmit();
      this.successMessage="Su código ha sido enviado de nuevo.";
    },
    onSubmitVerificacion(){
      this.errorMessage = "";
      this.successMessage = "";
      let data = {
        Email: this.email.trim(),
        CodigoValidacion: this.codigoVerificacion,
        ConvocatoriaId: this.$route.params.convocatoriaId,
        Code: this.$route.params.CodigoVotante
      };
      
      this.loading = true;  
      console.log('LOGIN');
      apiService.login(data).then((responseLogin) => {        
        try {
          if(responseLogin.data.errorMessage == ""){
               this.isLogin = true; 
               this.controlAsistenciaId = responseLogin.data.controlAsistenciaId               
          }
          else{
            this.errorMessage = responseLogin.data.errorMessage;
          }          
        }
        catch(error){
          console.log("isPropietarioOrRepresentante error", error);
        }
        this.loading = false;   
      });
    },
    onSubmitLogOut() {
      this.loading = true;  
       let data = {
        ConvocatoriaId: this.convocatoriaId,
        Code: this.code,
      };
      apiService.logOut(data).then((responseLoginOut) => {
        try {
          console.log("responseLoginOut", responseLoginOut);    
          this.isLogin = false;
          this.isPropietario = false;    
        }
        catch(error){
          console.log("LoginOut error", error);
        }
        this.loading = false;  
        console.log("enviar disable ", this.loading, this.errorMessage, this.successMessage);
      });
    }, 
    changeEmail(){
      this.errorMessage = "";
    },
    changeCodigoValidacion(){
      this.errorMessage = "";
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.form-input {
  padding: 12px;
}
button[disabled] {
  cursor: not-allowed;
}
.checkbox {
  width: 20px;
  height: 20px;
}
.checkbox-label {
  vertical-align: super;
}
</style>
